import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { BiPaperPlane, BiCloudDownload } from "react-icons/bi";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Accordion, Form } from "react-bootstrap";
import "./View.css";
import styled from "@emotion/styled";
import ReactToPrint from "react-to-print";
//import '../index.css';

class QuotationModal extends React.Component {
  constructor(props) {
    super(props);
    this.printRef = React.createRef();
    this.state = {
      productCategoryName: false,
      functionName: false,
      groupName: false,
      hsCode: false,
      brandName: false,
      countryOfOrigin: false,
      productLevel: false,
      productShelfLife: false,
      productPackSize: false,
      offerValidity: false,
      paymentTerm: false,
      deliveryLeadTime: false,
      vatAitCheck: false,
      reportRef: React.createRef(null),
    };
  }

  dateConverter = (date) => {
    var d = new Date(date);
    d = d.toString();
    console.log("d");
    console.log(d);
    d = d.split(" ");
    d = [d[2], d[1], d[3]].join("-");
    return d;
  };

  getPriceVatContent(pqdAppliedVatPercantage, pqdAppliedAitPercantage) {
    if (pqdAppliedVatPercantage === 0 && pqdAppliedAitPercantage === 0)
      return "Price Excluding VAT & AIT";
    if (pqdAppliedVatPercantage !== 0 && pqdAppliedAitPercantage === 0)
      return `Price Including ${parseFloat(pqdAppliedVatPercantage).toFixed(2)}% Vat & Excluding AIT`;
    if (pqdAppliedVatPercantage === 0 && pqdAppliedAitPercantage !== 0)
      return `Price Excluding Vat & Including ${parseFloat(pqdAppliedAitPercantage).toFixed(2)}% AIT`;
    if (pqdAppliedVatPercantage !== 0 && pqdAppliedAitPercantage !== 0)
      return `Price Including ${parseFloat(pqdAppliedVatPercantage).toFixed(2)}% VAT & ${parseFloat(pqdAppliedAitPercantage).toFixed(2)}% AIT`;
    return null;
  }
async GenerateInvoiceWithMultiplePage(soSystemNo) {
    // Create a new PDF document
    const pdf = new jsPDF({
        orientation: "portrait",
        unit: "pt",
        format: "a4",
    });

    const pageHeight = pdf.internal.pageSize.getHeight();
    let currentPageHeight = 0;

    // Helper function to add an image to the PDF
    const addImageToPDF = async (selector, xOffset = 0, yOffset = 0, widthOffset = 0) => {
        const element = document.querySelector(selector);
        if (!element) return;

        const canvas = await html2canvas(element);
        const canvasHeight = (canvas.height * pdf.internal.pageSize.getWidth()) / canvas.width;
        const imageData = canvas.toDataURL("image/png");

        // Check if a new page is needed
        if (currentPageHeight + canvasHeight > pageHeight) {
            pdf.addPage();
            currentPageHeight = 0;
        }
        pdf.addImage(
            imageData,
            "PNG",
            xOffset,
            currentPageHeight + yOffset,
            pdf.internal.pageSize.getWidth() - widthOffset,
            canvasHeight
        );
        currentPageHeight += canvasHeight;
    };

    // Add each section to the PDF
    await addImageToPDF(".invoice-header", 0.05);
    await addImageToPDF(".invoice-price-quotation", 5);
    await addImageToPDF(".invoice-table-header", 5);

    // Add each content item, ensuring headers are added on new pages if needed
    const contentElements = document.querySelectorAll(".invoice-table-item");
    for (const element of contentElements) {
        const canvas = await html2canvas(element);
        const canvasHeight = (canvas.height * pdf.internal.pageSize.getWidth()) / canvas.width;
        const imageData = canvas.toDataURL("image/png");

        // Check if a new page is needed
        if (currentPageHeight + canvasHeight > pageHeight) {
            pdf.addPage();
            await addImageToPDF(".invoice-table-header", 5, 0, 10);
            currentPageHeight = 0;
        }

        pdf.addImage(
            imageData,
            "PNG",
            5,
            currentPageHeight,
            pdf.internal.pageSize.getWidth() - 10,
            canvasHeight
        );
        currentPageHeight += canvasHeight;
    }
    // Add the footer to the PDF
    await addImageToPDF(".invoice-notes", 0.05, 10);
    // Save the PDF with the specified file name
    pdf.save(`price-quotation-${soSystemNo}.pdf`);
}
  render() {
    return (
      <div>
        <StyledQuotationModal
          fullscreen={true}
          show={this.props.showModal}
          onHide={this.props.closeModal}
          size="xl"
          centered
          className="full-sc"
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <Row responsive>
            <div className="pb-4 px-4">
              <Row>
              <Col md={6}>
              <div className="text-left" style={{ marginTop: '10px' }}> {/* Add margin to lower the position */}
                  {/* React to Print Add Print Button */}
                  <ReactToPrint
                    trigger={() => (
                      <button
                      style={{
                        backgroundColor: 'green',
                        borderColor: 'green',
                        color: 'white',
                        
                      }}
                      className="btn"
                    >
                      Print PDF
                    </button>
                    )}
                    content={() => this.printRef.current}
                    documentTitle={`price-quotation-${
                      this.props?.info?.data?.priceQuotationDetailList[0]
                      ?.pqSystemNo
                    }`} // Setting the filename here
                  />
                </div>
                </Col>
                <Col md={2}>
                  <Button
                    variant="primary"
                    style={{
                      backgroundColor: 'blue',
                      borderColor: 'blue',
                      color: 'white',
                      display: 'none',
                    }}

                    className="btn"
                      onClick={() => {
                      // GenerateInvoice(10);
                      this.GenerateInvoiceWithMultiplePage(
                        this.props?.info?.data?.priceQuotationDetailList[0]
                          ?.pqSystemNo
                      );
                    }}
                  >
                    Download
                  </Button>
                </Col>
              </Row>
            </div>
            <Col
              md={8}
              lg={2}
              style={{
                transition: "all 0.5s ease-out",
              }}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Customize</Accordion.Header>
                  <Accordion.Body>
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Categoryname"
                      className=""
                      checked={this.state.productCategoryName}
                      onChange={(e) => {
                        this.setState({
                          productCategoryName: !this.state.productCategoryName,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Functionname"
                      className=""
                      checked={this.state.functionName}
                      onChange={(e) => {
                        this.setState({
                          functionName: !this.state.functionName,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Groupname"
                      className=""
                      checked={this.state.groupName}
                      onChange={(e) => {
                        this.setState({
                          groupName: !this.state.groupName,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="HS Code"
                      className=""
                      checked={this.state.hsCode}
                      onChange={(e) => {
                        this.setState({
                          hsCode: !this.state.hsCode,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Brandname"
                      className=""
                      checked={this.state.brandName}
                      onChange={(e) => {
                        this.setState({
                          brandName: !this.state.brandName,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Origin"
                      className=""
                      checked={this.state.countryOfOrigin}
                      onChange={(e) => {
                        this.setState({
                          countryOfOrigin: !this.state.countryOfOrigin,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Product Level"
                      className=""
                      checked={this.state.productLevel}
                      onChange={(e) => {
                        this.setState({
                          productLevel: !this.state.productLevel,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Product Shelf Life"
                      className=""
                      checked={this.state.productShelfLife}
                      onChange={(e) => {
                        this.setState({
                          productShelfLife: !this.state.productShelfLife,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Product Pack Size"
                      className=""
                      checked={this.state.productPackSize}
                      onChange={(e) => {
                        this.setState({
                          productPackSize: !this.state.productPackSize,
                        });
                      }}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Notes</Accordion.Header>
                  <Accordion.Body>
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Payment Term- Cash/Cheque/LC within seven days of goods delivery."
                      className=""
                      checked={this.state.paymentTerm}
                      onChange={(e) => {
                        this.setState({
                          paymentTerm: !this.state.paymentTerm,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Offer validity for 15days."
                      className=""
                      checked={this.state.offerValidity}
                      onChange={(e) => {
                        this.setState({
                          offerValidity: !this.state.offerValidity,
                        });
                      }}
                    />
                    <Form.Check
                      type="checkbox"
                      id="custom-checkbox"
                      label="Delivery Lead time - 24hours."
                      className=""
                      checked={this.state.deliveryLeadTime}
                      onChange={(e) => {
                        this.setState({
                          deliveryLeadTime: !this.state.deliveryLeadTime,
                        });
                      }}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
            <Col
              md={8}
              lg={10}
              style={{
                transition: "all 0.5s ease-out",
              }}
            >
              {/* Printable Area */}
          {/* Ensure that ref is correctly attached to this div */}
              <div
                ref={this.printRef} // Attach the ref here
                //ref={this.state.reportRef}
                id="invoiceCapture"
                className=" pt-1 "
                style={{ position: "relative" }}
                
              >
                <div className="d-flex flex-row justify-content-between align-items-center bg-light w-100 py-4 px-2 invoice-header">
                  <div className="text-end pr-5 pl-5">
                    <img
                      src={process.env.PUBLIC_URL + "/images/trade.png"}
                      alt=""
                      width="130"
                      height="35"
                      style={{ width: "85px", height: "60px" }}
                    />
                  </div>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <h4 className="fw-bold my-2">TRADE ZENTRUM</h4>
                    <h6 className="fw-bold text-secondary mb-1">
                      Reg. Office: House #21, Road #01, Block-C, Banasree, Rampura
                    </h6>
                    <h6 className="fw-bold text-secondary mb-1 d-flex align-items-center justify-content-between">
                      <div className="fw-bold text-secondary mb-1 d-flex align-items-center">
                        ☎️&nbsp;+8801792 527261
                      </div>{" "}
                      &nbsp; &nbsp; &nbsp;
                      <div className="fw-bold text-secondary mb-1 d-flex align-items-center">
                        📩&nbsp;tradezentrumbd@gmail.com
                      </div>
                    </h6>
                  </div>
                  <div className="text-end pr-5 pl-5">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/assutext-logo.jpeg"
                      }
                      alt=""
                      width="130"
                      height="35"
                      style={{ width: "120px", height: "50px" }}
                    />
                  </div>
                </div>
                <div
                  className="p-4"
                  style={{
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div
                    className="invoice-price-quotation"
                    style={{ padding: "10px" }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <span
                        style={{
                          fontWeight: "bolder",
                          padding: "5px",
                        }}
                      >
                        PRICE QUOTATION
                      </span>
                    </div>
                    <Row style={{ marginBottom: "3rem", height: "100px" }}>
                      <Col md={7} lg={8}>
                        <div>
                          Quotation&nbsp;Number:&nbsp; :{" "}
                          {this.props.info.data.priceQuotationMasterData
                            ?.pqSystemNo || ""}
                        </div>
                        <div>
                          Date Of Issue:&nbsp;
                          {this.dateConverter(
                            this.props.info.data.priceQuotationMasterData
                              ?.pqOrderDate
                          ) || ""}
                        </div>
                      </Col>
                      <div>
                        Customer&nbsp;Name:&nbsp; :{" "}
                        {this.props.info.data.priceQuotationMasterData
                          ?.customerName || ""}
                      </div>
                      <div>
                        Customer Address:&nbsp;
                        {this.props.info.data.priceQuotationMasterData
                          .customerAddress !== null
                          ? this.props.info.data.priceQuotationMasterData
                              .customerAddress
                          : "Not Available"}
                      </div>
                    </Row>
                  </div>
                  <div
                    style={{
                      width: "auto",
                      overflowX: "scroll",
                      padding: "10px",
                    }}
                    className="row-scrollers"
                  >
                    <Table
                      className="mb-0"
                      style={{
                        border: "1px solid #e3e3e3",
                      }}
                    >
                      <thead>
                        <tr className="invoice-table-header">
                          <th>PRODUCT NAME</th>
                          {this.state.productCategoryName && <th>Category</th>}
                          {this.state.functionName && <th>Function</th>}
                          {this.state.groupName && <th>Group</th>}
                          {this.state.hsCode && <th>HS Code</th>}
                          {this.state.brandName && <th>Brand</th>}
                          {this.state.countryOfOrigin && <th>Origin</th>}
                          {this.state.productLevel && <th>Product Level</th>}
                          {this.state.productShelfLife && <th>Shelf Life</th>}
                          {this.state.productPackSize && <th>Pack Size</th>}
                          <th className="text-end">PRICE/KG</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.info.data.priceQuotationDetailList.map(
                          (item, i) => {
                            console.log("item");
                            console.log(item);
                            return (
                              <tr
                                id={i}
                                key={i}
                                style={{ height: "20px" }}
                                className="invoice-table-item"
                              >
                                <td>{item.productName}</td>
                                {this.state.productCategoryName && (
                                  <td>{item.productCategoryName}</td>
                                )}
                                {this.state.functionName && (
                                  <td>{item.functionName}</td>
                                )}

                                {this.state.groupName && (
                                  <td>{item.groupName}</td>
                                )}
                                {this.state.hsCode && <td>{item.hsCode}</td>}

                                {this.state.brandName && (
                                  <td>{item.brandName}</td>
                                )}
                                {this.state.countryOfOrigin && (
                                  <td>{item.countryOfOrigin}</td>
                                )}

                                {this.state.productLevel && (
                                  <td>{item.productLevel}</td>
                                )}
                                {this.state.productShelfLife && (
                                  <td>{item.productShelfLife}</td>
                                )}
                                {this.state.productPackSize && (
                                  <td>{item.productPackSize || "0"}</td>
                                )}
                                <td
                                  className="text-end"
                                  style={{ width: "100px" }}
                                >
                                {this.props.info.data.priceQuotationMasterData
                              .pqCurrencyName}{" "}
                                  {item.pqdFinalPrice}
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className="p-4 invoice-notes">
                  <h5>Notes:</h5>
                  <ul style={{ listStyleType: "disc" }}>
                    <li>
                      {this.getPriceVatContent(
                        this.props?.info?.data?.priceQuotationDetailList[0]
                          ?.pqdAppliedVatPercantage,
                        this.props?.info?.data?.priceQuotationDetailList[0]
                          ?.pqdAppliedAitPercantage
                      )}
                    </li>
                    {this.state.vatAitCheck && (
                      <li>
                        Payment Term- Cash/Cheque/LC within seven days of goods delivery.
                      </li>
                    )}
                    {this.state.paymentTerm && (
                      <li>
                        Payment Term- Cash/Cheque/LC within seven days of goods delivery.
                      </li>
                    )}
                    {this.state.offerValidity && (
                      <li>Offer validity for 15days.</li>
                    )}
                    {this.state.deliveryLeadTime && (
                      <li>Delivery lead time - 24 hours.</li>
                    )}
                  </ul>
                </div>
                <div className="p-4 " style={{ display: "none" }}>
                  <Row className="mb-4 ">
                    <Col md={3} lg={3}>
                      <div
                        className="fw-bold text-center w-55 h-55 p-1"
                        style={{
                          height: "120px",
                          border: "1px solid #e3e3e3 ",
                        }}
                      >
                        Authorized By:
                      </div>
                    </Col>
                    <Col md={3} lg={3}>
                      <div
                        className="fw-bold text-center w-55 h-55 p-1"
                        style={{
                          height: "120px",
                          border: "1px solid #e3e3e3 ",
                        }}
                      >
                        Prepared By:
                      </div>
                    </Col>
                    <Col md={3} lg={3}>
                      <div
                        className="fw-bold text-center w-55 h-55 p-1"
                        style={{
                          height: "120px",
                          border: "1px solid #e3e3e3 ",
                        }}
                      >
                        Mode of Transport:
                      </div>
                    </Col>
                    <Col md={3} lg={3}>
                      <div
                        className="fw-bold text-center w-55 h-55 p-1"
                        style={{
                          height: "120px",
                          border: "1px solid #e3e3e3 ",
                        }}
                      >
                        Received By:
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </StyledQuotationModal>
        <hr className="mt-4 mb-3" />
      </div>
    );
  }
}

const StyledQuotationModal = styled(Modal)`
  @import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

  .modal-dialog {
    font-family: "Roboto", sans-serif;
    max-width: 80%; /* Set the max-width to 80% of the viewport */
    max-height: 80vh; /* Set the max-height to 80% of the viewport height */
    width: auto;
    margin: auto; /* Center the modal */

    .modal-content {
      display: flex;
      flex-direction: column;
      max-height: 80vh; /* Limit modal height */
      overflow-y: auto; /* Allow scrolling inside the modal */
      padding: 1rem;

      /* Ensure buttons and header are always visible */
      .modal-header, .modal-footer {
        flex-shrink: 0; /* Prevent shrinking */
      }

      /* Scrollable body */
      .modal-body {
        overflow-y: auto; /* Make only the body scrollable */
        flex-grow: 1; /* Allow the body to grow and take available space */
      }
    }
  }
`;


export default QuotationModal;