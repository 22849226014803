// Import required libraries and components from React, Redux, and Material UI
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid, Paper, Toolbar, Button as MuiButton, createFilterOptions, Autocomplete, ButtonGroup,
} from "@mui/material";
import { DataGrid, GridToolbar, GridEventListener } from "@mui/x-data-grid";
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Input from "../../core/controls/Input";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import InventoryIcon from "@mui/icons-material/Inventory";
import Popup from "../../core/Popup";
import Notification from "../../core/Notification";
import ConfirmDialog from "../../core/ConfirmDialog";
import { Form } from "react-bootstrap";
import { InfinitySpin, ThreeDots } from "react-loader-spinner";
import {
  listStokInInvoiceAction, detailsStokInDataByIdAction
} from "../../redux/actions/stockInActions";
import { listSuppliersForDropdown } from "../../redux/actions/supplierActions";
import StokInChallanModal from "../../core/StokInChallanModal";
import { listCustomersForSignup } from "../../redux/actions/userActions";
import { useFormik } from "formik";
import useStyles from "./StockInInvoiceListStyle";

// Set up date for initial values
var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

// Define initial form values
const initialValues = {
  startDate: [date.getFullYear(), mnth, day].join("-"),
  endDate: [date.getFullYear(), mnth, day].join("-"),
  customer: {
    id: "",
    label: "",
  },
};

// Main functional component
const StockInInvoiceList = ({ history }) => {
  // Initialize dispatch function and styles
  const dispatch = useDispatch();
  const classes = useStyles();

  // Set up Redux selectors to access various states
  const listStockInInvoiceState = useSelector((state) => state.listStockInInvoiceState);
  const { loading2, error2, stockin } = listStockInInvoiceState;
  const customerList = useSelector((state) => state.customerList);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    customers,
  } = customerList;
  const supplierDropdownList = useSelector((state) => state.supplierDropdownList);
  const {
    loading: loadingSuppliers,
    error: errorSupplier,
    suppliers: suppliersDropdown,
  } = supplierDropdownList;
  const stockInDataByIdState = useSelector((state) => state.stockInDataByIdState);
  const { loading: loadingData, error: errorData, stockindata } = stockInDataByIdState;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // State variables for popup, notification, and confirmation dialogs
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  // Other state variables for pagination, row selection, and modals
  const [pageSize, setPageSize] = useState(25);
  const [rowId, setRowId] = useState(null);
  const [rowClicked, setRowClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [whatOpen, setWhatOpen] = useState(-1);
  const [selectedOrder, setSelectedOrder] = useState("3");
  const [isOpenInvoice, setIsOpenInvoice] = useState(false);
  const selectedOrderRef = useRef(null);

  // Function to generate random values for unique identifiers
  const generateRandom = () => {
    var length = 8,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

  // Formik setup for handling form state and submission
  const formik = useFormik({
    initialValues,
    onSubmit: (values, action) => {
      let data = {
        supplierId: values.supplier?.id ? values.supplier.id : 0,
        customerId: values.customer?.id ? values.customer.id : 0,
      };
      // Dispatch action to fetch stock data based on selected criteria
      dispatch(listStokInInvoiceAction({ supplierId: data.supplierId }, { customerId: data.customerId }))
        .then((result) => {
          console.log(result);
          if (result?.title === "One or more validation errors occurred.") {
            action.setSubmitting(false);
          }
        });
    },
  });

  // Fetch initial data based on user login state
  useEffect(() => {
    if (userInfo) {
      dispatch(listCustomersForSignup());
      dispatch(listSuppliersForDropdown());
    }
  }, [dispatch, classes, history, userInfo, selectedOrder]);

  // Function to render data grid based on sales order type
  const gridBasedOnSalesOrderType = () => {
    return (
      <div className={classes.colHeader} >
        <br/>
        <DataGrid
          className={classes.grid}
          columnBuffer={2}
          columnThreshold={2}
          disableVirtualization
          checkboxSelection
          rows={stockin === undefined ? [] : stockin}
          columns={
            selectedOrder === "3"
              ? [ ...columns_part_b_O_3,...columns_part_a]
              : columns_part_a
          }
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row.siSystemId + "-" + generateRandom()}
          onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </div>
    );
  };

  // Handler function for row selection changes
  const onRowsSelectionHandler = (ids) => {
    if (ids.length === 1) {
      const selectedId = parseInt(ids[0].split("-")[0], 10);
      const selectedRow = stockin.find((row) => row.siSystemId === selectedId);

      if (selectedRow) {
        setRowId(selectedRow.siSystemId);
        setRowClicked(!rowClicked);
      }
    } else {
      setRowId(null);
      setRowClicked(false);
    }
  };

  // Open and close invoice modal functions
  const openInvoiceModal = (id) => {
    setWhatOpen(1);
    dispatch(detailsStokInDataByIdAction(id)).then((res) => {
      setIsOpenInvoice(true);
    });
  };
  const closeInvoiceModal = (event) => {
    setWhatOpen(-1);
    setIsOpenInvoice(false);
  };

  // Define columns for the data grid
  const columns_part_a = [
    { field: "jobType", headerName: "Order Type", headerAlign: "center", align: "center" },
    { field: "siSystemNo", headerName: "System Invoice", headerAlign: "center", align: "center"},
    { field: "siInvoiceNo", headerName: "Customer Invoice", headerAlign: "center", align: "center"},
    { field: "siInvoiceDate", headerName: "Invoice Date", headerAlign: "center", align: "center" },
    { field: "customerName", headerName: "Partner Name", headerAlign: "center", align: "center", valueGetter: (params) => params.row.customerName || params.row.supplierName },
    { field: "siSystemId", headerName: "System Id", headerAlign: "center", align: "center" },
  ];

  const columns_part_b_O_3 = [
    {
      field: "actions4",
      headerName: "CHALAAN",
      type: "actions",
      renderCell: (params) => {
        const { row, ...rest } = params;
        return (
          <MuiButton
            title="CHALAAN"
            focuselementref={selectedOrderRef}
            startIcon={<InventoryIcon fontSize="small" />}
            text="CHALAAN"
            color="warning"
            onClick={() => {
              openInvoiceModal(row.siSystemId);
            }}
          />
        );
      },
    },
  ];

  // Callback function for checking if options equal values in Autocomplete
  const isOptionEqualToValue = useCallback(
    (option, value) => option.value === value.value
  );

  // Handlers for changes in customer and supplier selections
  const customerChange = useCallback((e, v) => {
  // If customer is selected, clear supplier
  if (v) {
    formik.setFieldValue("supplier", { id: "", label: "" });
  }
  formik.setFieldValue("customer", {
    id: v?.id || "",
    label: v?.label || "",
  });
});

const supplierChange = useCallback((e, v) => {
  // If supplier is selected, clear customer
  if (v) {
    formik.setFieldValue("customer", { id: "", label: "" });
  }
  formik.setFieldValue("supplier", {
    id: v?.id || "",
    label: v?.label || "",
  });
});

  
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });
 
  return (
    
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Stock In Challan Information........."
        subtitle="Generate Challan"
      />
      {loading2 && <>loading..</>}
      {
        <>
          <Paper className={`${classes.pageContent2} paperdesign1`}>
            <Form onSubmit={formik.handleSubmit}>
            <Grid container sx={{ padding: 2, backgroundColor: '#ececec', justifyContent: 'center'  }} >

              <Grid item xs={12} md={3} sx={{ marginRight: 2 }}>
                <Autocomplete
                    size="small"
                    disabled={loadingSuppliers ? true : false}
                    id="combo-box-demo-channel"
                     isOptionEqualToValue={isOptionEqualToValue}
                    onChange={supplierChange}
                    value={formik.values.supplier}
                    options={suppliersDropdown !== undefined ? suppliersDropdown : []}
                    onOpen={formik.handleBlur}
                    //filterOptions={filterOptions}
                    renderInput={(params) => (
                      <Input
                        label="Supplier Name"
                        name="supplier"
                        error2={
                          formik.errors.supplier !== undefined
                            ? formik.errors.supplier.id
                            : ""
                        }
                        touched={
                          formik.touched.supplier !== undefined
                            ? formik.touched.supplier
                            : ""
                        }
                        {...params}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3} sx={{ marginRight: 2 }}>
                  
                  <Autocomplete
                    size="small"
                    disabled={loadingCustomer ? true : false}
                    id="combo-box-demo-channel"
                     isOptionEqualToValue={isOptionEqualToValue}
                    onChange={customerChange}
                    value={formik.values.customer}
                    options={customers !== undefined ? customers : []}
                    onOpen={formik.handleBlur}
                    //filterOptions={filterOptions}
                    renderInput={(params) => (
                      <Input
                        label="Customer Name"
                        name="customer"
                        error2={
                          formik.errors.customer !== undefined
                            ? formik.errors.customer.id
                            : ""
                        }
                        touched={
                          formik.touched.customer !== undefined
                            ? formik.touched.customer
                            : ""
                        }
                        {...params}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  />
                </Grid>
                
              <Grid item xs={12} md={3}>
                <ButtonGroup className={classes.submitButtonGroup}>
                    <MuiButton
                        startIcon={
                          loadingData ? <CircularProgress size="1rem" color="error2" /> : <SearchIcon />
                        }
                        size="large"
                        type="submit" // This should be the only submit button
                    >
                        Search
                    </MuiButton>
                    <MuiButton
                        size="small"
                        startIcon={<ReplayIcon />}
                        onClick={() => {
                        formik.resetForm();
                        }}
                    />
                    </ButtonGroup>
                </Grid>
              </Grid>
            </Form>
          </Paper>
          {error2 === "Network Error" ? (
            <div className={classes.wrapperWhite}>
              <Toolbar
                className={classes.toolBar}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  className="m-4"
                  src={process.env.PUBLIC_URL + "/images/networkerror.png"}
                  alt=""
                  width="130"
                  height="35"
                  style={{ width: "500px", height: "auto" }}
                />
              </Toolbar>
            </div>
          ) : loadingData ? (
            <div
              className="text-center"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <InfinitySpin width="200" color="#6C4AB6" />
            </div>
          ) : (
            <div className={classes.wrapperWhite}>
              {gridBasedOnSalesOrderType()}
              {JSON.stringify(stockindata) !== "{}" &&
                  stockindata !== undefined &&
                  whatOpen === 1 && (
                  <StokInChallanModal
                    showModal={isOpenInvoice}
                    closeModal={closeInvoiceModal}
                    info={stockindata}
                  />
              )}
              <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />             
              <Notification notify={notify} setNotify={setNotify} />
              <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
              />
            </div>
          )}
        </>
      }
    </div>
  );
};

export default StockInInvoiceList;
