import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { BiPaperPlane } from "react-icons/bi";
import "./View.css";
import ReactToPrint from "react-to-print";

class StokInChallanModal extends React.Component {
  constructor(props) {
    super(props);
    this.pdfRef = React.createRef();
  }

  dateConverter = (date) => {
    var d = new Date(date);
    d = d.toString();
    d = d.split(" ");
    d = [d[2], d[1], d[3]].join("-");
    return d;
  };

  getTotalQuantity = (arr) => {
    return arr.reduce((sum, item) => {
      return sum + item.sipPackSize * item.sipPackQty;
    }, 0).toFixed(2);
  };
  
  render() {
    return (
      <div>
        <Modal
          show={this.props.showModal}
          onHide={this.props.closeModal}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>Modal heading</Modal.Title> */}
          </Modal.Header>
          <div
            id="invoiceCapture"
            className=" pt-1 "
            style={{ position: "relative" }}
            ref={this.pdfRef}
          >
            <div className="d-flex flex-row justify-content-between align-items-center bg-light w-100 py-4 px-2 invoice-header">
              <div className="text-end pr-5 pl-5">
                <img
                  src={process.env.PUBLIC_URL + "/images/trade.png"}
                  alt=""
                  width="130"
                  height="35"
                  style={{ width: "80px", height: "50px" }}
                />
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <h4 className="fw-bold my-2">TRADE ZENTRUM</h4>
                <h6 className="fw-bold text-secondary mb-1">
                <div style={{ textAlignVertical: "center",textAlign: "center",}}>
                  Reg. Office: House #21, Road #01, Block-C, Banasree, Rampura, Dhaka
                  </div>
                </h6>
                <h6 className="fw-bold text-secondary mb-1 d-flex align-items-center justify-content-between">
                  <div className="fw-bold text-secondary mb-1 d-flex align-items-center">
                    ☎️&nbsp;+8801792 527261
                  </div>{" "}
                  &nbsp; &nbsp; &nbsp;
                  <div className="fw-bold text-secondary mb-1 d-flex align-items-center">
                    📩&nbsp;tradezentrumbd@gmail.com
                  </div>
                </h6>
              </div>
              <div className="text-end pr-5 pl-5">
                <img
                  src={process.env.PUBLIC_URL + "/images/assutext-logo.jpeg"}
                  alt=""
                  width="130"
                  height="35"
                  style={{ width: "120px", height: "50px" }}
                />
              </div>
            </div>
            <div
              className="p-4"
              style={{
                backgroundSize: "500px",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <span
                  style={{
                    fontWeight: "bolder",
                    padding: "5px",
                  }}
                >
                  CHALLAN
                  <br />
                  <br />
                </span>
              </div>
              <Row style={{ marginBottom: "3rem", height: "100px" }}>
                <Col md={7} lg={8}>
               <div>
                    {this.props.info?.supplierName ? (
                      <>Supplier Name&nbsp; : {this.props.info.supplierName}</>
                    ) : (
                      <>Customer Name&nbsp; : {this.props.info?.customerName || "Not Available"}</>
                    )}
                </div>
                <div>
                {this.props.info?.supplierAddress ? (
                      <>Supplier Address&nbsp; : {this.props.info.supplierAddress}</>
                    ) : (
                      <>Customer Address&nbsp; : {this.props.info?.customerAddress || "Not Available"}</>
                    )}
                </div>
                </Col>

                <Col md={5} lg={4}>
                  <div>
                    Challan&nbsp;Number&nbsp; :{" "}
                    {this.props.info?.siSystemNo || ""}
                  </div>

                  <div>
                    Customer&nbsp;Challan&nbsp;Number&nbsp; :{" "}
                    {this.props.info?.siInvoiceNo || ""}
                  </div>

                  <div>
                    Date Of Issue&nbsp;:&nbsp;
                    {this.dateConverter(this.props.info.siInvoiceDate) || ""}
                  </div>
                  {this.props.info?.jobType !== "Cash_Sales" &&
                  this.props.info?.jobType !== "Credit_Sales" ? (
                    <div>
                      Order Type&nbsp;:&nbsp;
                      {this.props.info?.jobType || ""}
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              <div
                style={{
                  width: "auto",
                  overflowX: "scroll",
                }}
                className="row-scrollers"
              >
                <Table className="mb-0" style={{ border: "1px solid #e3e3e3" }}>
                  <thead>
                    <tr>
                      <th>Product Name</th>
                      <th className="text-center">Pack Size(KG)</th>
                      <th className="text-center">Pack Type</th>
                      <th className="text-center">No of Pack</th>
                      <th className="text-center">Line Quantity</th> {/* New header */}
                    </tr>
                  </thead>
                  <tbody>
              
                    {this.props.info.stockInItemsData.map((item, i) => {
                      return (
                        <tr id={i} key={i} style={{ height: "20px" }}>
                            <td>
                        <var>
                          {item.productName}              
                          </var>
                      </td>
                          <td
                            className="text-center"
                            style={{ width: "100px" }}
                          >
                            {item.sipPackSize}
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "160px", whiteSpace: "nowrap", textAlign: "center" }} // Increased width and reduced font size
                          >
                            {item.packTypeName}
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "100px" }}
                          >
                            {item.sipPackQty}
                          </td>
                          <td className="text-center" style={{ width: "100px" }}>
                          {item.sipPackSize * item.sipPackQty} {/* Display total quantity */}
                        </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <Table style={{ marginTop: "2rem", border: "1px solid #e3e3e3" }}>
                <tbody>
                  <tr className="text-end">
                    <td></td>
                    <td className="fw-bold">
                      Total Quantity (KG)
                    </td>
                    <td className="fw-bold" style={{ width: "100px" }}>
                      {this.getTotalQuantity(
                        this.props.info?.stockInItemsData
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
              {this.props.info.notes && (
                <div className="bg-light py-3 px-4 rounded">
                  {this.props.info.notes}
                </div>
              )}
            </div>
           
            <footer>
              <div style={{ textAlignVertical: "center", textAlign: "center" }}>
                  {this.props.info?.siRemarks ? (
                      <>
                          <label>Remarks :</label> {this.props.info.siRemarks}
                      </>
                  ) : null}
              </div>
          </footer>

          </div>
          <div className="pb-4 px-4">
            <Row>
            
              <Col md={6} className="mb-2">
                <ReactToPrint
                  trigger={() => (
                    <Button
                      variant="primary"
                      className="d-flex align-items-center justify-content-center w-100"
                    >
                      <BiPaperPlane
                        style={{
                          width: "15px",
                          height: "15px",
                          marginTop: "-3px",
                          
                        }}
                        className="me-2"
                      />
                      Download as PDF
                    </Button>
                  )}
                  content={() => this.pdfRef.current}
                  documentTitle={`IN_Challan-${this.props.info.supplierName || this.props.info?.customerName}-${this.props?.info?.siInvoiceNo}`}
                />
              </Col>
              <Col md={6}>
              </Col>
            </Row>
          </div>
        </Modal>
        <hr className="mt-4 mb-3" />
      </div>
    );
  }
}
export default StokInChallanModal;
