import React, { useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  InputAdornment,
  Button as MuiButton,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";

import { Link, withRouter } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import PublishIcon from "@mui/icons-material/Publish";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ButtonGroup from "@mui/material/ButtonGroup";

import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import { useForm, Form } from "../../core/useForm";
import Input from "../../core/controls/Input";
import RadioGroup from "../../core/controls/RadioGroup";
import Select from "../../core/controls/Select";
import Checkbox from "../../core/controls/Checkbox";
import Datepicker from "../../core/controls/Datepicker";
import Button from "../../core/controls/Button";
import {
  ChequeSchema,
  InhouseEmployeeSchema,
  PaymentSchema,
} from "../../schemas";
import { createEmployee } from "../../redux/actions/employeeActions";
import { useEffect } from "react";
import { API_URL } from "../../redux/constants/apiConstants";
import { EMPLOYEE_CREATE_RESET } from "../../redux/constants/employeeConstants";
import Notification from "../../core/Notification";
import {
  listChannel,
  listDeparment,
  listDesignation,
} from "../../redux/actions/channelActions";

import "../Customers/Customer.css";
import { Circles } from "react-loader-spinner";
import {
  listCustomersForSignup,
  listEmployees,
  listEmployeesForDropdown,
} from "../../redux/actions/userActions";
import {
  createCheque,
  listBank,
  listChequeDetails,
  listChequeStatus,
  listChequeType,
} from "../../redux/actions/chequeActions";
import {
  CHEQUE_CREATE_RESET,
  CHEQUE_DETAILS_RESET,
} from "../../redux/constants/chequeConstants";
import {
  createPayment,
  listInvoiceCheckKeyValue,
  listInvoiceDetails,
  listPaymentDetails,
  listPaymentHistoryDetails,
  listPaymentMode,
  updatePayment,
} from "../../redux/actions/paymentActions";
import {
  INVOICE_DETAILS_RESET,
  PAYMENT_CREATE_RESET,
  PAYMENT_DETAILS_RESET,
  PAYMENT_HISTORY_DETAILS_RESET,
} from "../../redux/constants/paymentConstants";
import Popup from "../../core/Popup";
import PaymentHistoryView from "../../core/PaymentHistoryView";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
const genderItems = [
  { id: "male", title: "Male" },
  { id: "female", title: "Female" },
  { id: "other", title: "Other" },
];

const getDepartmentCollection = [
  { id: "1", title: "Development" },
  { id: "2", title: "Marketing" },
  { id: "3", title: "Accounting" },
  { id: "4", title: "HR" },
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(5),
    padding: useTheme().spacing(3),
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

const initialValues = {
  isInvoice: true,
  employee: {
    id: "",
    label: "",
  },
  paymentReference: "0",
  paymentMode: {
    id: "",
    label: "",
  },
  invoiceCheque: {
    id: "",
    label: "",
  },
  customer: {
    id: "",
    label: "",
  },
  moneyReceiptNo: "",
  invoiceNumber: "",
  paidAmount: "",
  balanceAmount: "",
  invoiceAmount: "",
  receiveAmount: "",
  adjustedAmount: "",
  adjustedAmountShow: "",
  paymentDate: [date.getFullYear(), mnth, day].join("-"),
  paymentNote: "",

  chequeDate: [date.getFullYear(), mnth, day].join("-"),
  chequeActionDate: [date.getFullYear(), mnth, day].join("-"),
  chequeNumber: "",
  chequeAmount: "",
  bank: {
    id: "",
    label: "",
  },

  chequeStatus: {
    id: 2,
    label: "Cleared",
  },
};

const EditPayment = ({ match, history }) => {
  const paymentId = match.params.id;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [locations, setLocations] = useState([]);
  const [employeeTypes, setEmployeeTypes] = useState([]);
  const [order, setOrder] = useState("0");
  const [isOpen, setIsOpen] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [checked, setChecked] = useState(true);
  // const { values, setValues, handleChange } = useForm(initialValues);

  const customerList = useSelector((state) => state.customerList);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    customers,
  } = customerList;

  const invoiceChequeList = useSelector((state) => state.invoiceChequeList);
  const {
    loading: loadingInvoiceCheque,
    error: errorInvoiceCheque,
    invoicecheques,
  } = invoiceChequeList;

  const paymentModeList = useSelector((state) => state.paymentModeList);
  const {
    loading: loadingPaymentMode,
    error: errorPaymentMode,
    paymentmodes,
  } = paymentModeList;

  const employeeList = useSelector((state) => state.employeeList);
  const {
    loading: loadingEmployee,
    error: errorEmployee,
    employees,
  } = employeeList;

  const chequeTypeList = useSelector((state) => state.chequeTypeList);
  const {
    loading: loadingChequeType,
    error: errorChequeType,
    chequeTypes,
  } = chequeTypeList;

  const invoiceDetailsReducer = useSelector(
    (state) => state.invoiceDetailsReducer
  );
  const {
    loading: loadingInvoiceDetailsReducer,
    error: errorInvoiceDetailsReducer,
    invoiceSingle,
  } = invoiceDetailsReducer;

  const chequeDetails = useSelector((state) => state.chequeDetails);
  const {
    loading: loadingChequeDetails,
    error: errorChequeDetails,
    chequeSingle,
  } = chequeDetails;

  const paymentHistoryDetails = useSelector(
    (state) => state.paymentHistoryDetails
  );
  const {
    loading: loadingPaymentHistoryDetails,
    error: errorPaymentHistoryDetails,
    paymentHistoryList,
  } = paymentHistoryDetails;

  const bankList = useSelector((state) => state.bankList);
  const { loading: loadingBanks, error: errorBanks, banks } = bankList;

  const chequeStatusList = useSelector((state) => state.chequeStatusList);
  const {
    loading: loadingChequeStatus,
    error: errorChequeStatus,
    chequestatuss,
  } = chequeStatusList;

  const channelList = useSelector((state) => state.channelList);
  const { loading: loadingChannels, error, channels } = channelList;

  const departmentList = useSelector((state) => state.departmentList);
  const {
    loading: loadingDepartments,
    error: errorDepartments,
    departments,
  } = departmentList;

  const designationList = useSelector((state) => state.designationList);
  const {
    loading: loadingDesignations,
    error: errorDesignations,
    designations,
  } = designationList;

  const paymentCreate = useSelector((state) => state.paymentCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    payment: createdPayment,
  } = paymentCreate;

  const paymentDetails = useSelector((state) => state.paymentDetails);
  const {
    loading: loadingPaymentDetails,
    error: errorPaymentDetails,
    paymentSingle,
  } = paymentDetails;

  const paymentUpdate = useSelector((state) => state.paymentUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = paymentUpdate;
  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const formik = useFormik({
    initialValues,
    validationSchema: PaymentSchema,
    onSubmit: (values, action) => {
      dispatch(
        updatePayment({
          paymentSystemId: paymentId,
          paymentCustomerId: values.customer.id,
          chequeAmount:
            values.paymentReference === "0" || values.paymentReference === "2" || values.paymentReference === "3"
              ? null
              : values.receiveAmount,
          paymentAmount:
            values.paymentReference === "0" || values.paymentReference === "2" || values.paymentReference === "3"
              ? values.receiveAmount
              : null,
          paymentRelatedJobId:
            values.paymentReference === "0" ? values.invoiceCheque.id : null,
          paymentAdjustedAmount:
            values.paymentReference === "0"
              ? values.adjustedAmount === ""
                ? null
                : values.adjustedAmount
              : null,
          paymentMethodId: values.paymentMode.id,
          paymentDate: values.paymentDate,
          paymentCollectedBy: values.employee.id,
          // moenyRecepitNo:
          //   values.moneyReceiptNo === "" ? null : values.moneyReceiptNo,
          moenyRecepitNo:
            values.paymentReference === "3"
              ? ""
              : values.moneyReceiptNo,

          paymentChequeId:
            values.paymentReference === "0" || values.paymentReference === "2" || values.paymentReference === "3"
              ? null
              : values.invoiceCheque.id,
          paymentNote: values.paymentNote,
          createdBy: values.createdBy,
          createdDate: values.createdDate,
           modifiedBy: values.modifiedBy,
           modifiedDate: values.modifiedDate,
           paymentCollectedChannelId:values.paymentCollectedChannelId,
          chequeBeneficiaryBankId:
            values.paymentReference === "0" || values.paymentReference === "2"
              ? null
              : values.bank.id,
          chequeStatusId:
            values.paymentReference === "0" || values.paymentReference === "2" || values.paymentReference === "3"
              ? null
              : values.chequeStatus.id,
          chequeActionDate:
            values.paymentReference === "0" || values.paymentReference === "2" || values.paymentReference === "3"
              ? null
              : values.chequeActionDate,
        })
      ).then((result) => {
        if (result?.title === "One or more validation errors occurred.") {
          // setOpenPopup({
          //   ...openPopup,
          //   title: "ERROR!!!",
          //   subTitle: "Something went wrong!",
          //   isOpen: true,
          // });
          // action.setFieldError("error", result.errMsg);
          action.setSubmitting(false);
        } else if (result?.errMsg) {
          if (result.errMsg == "Customer Already Exist") {
            action.setFieldError("errorClientName", result.errMsg);
          } else if (result.errMsg == "Contact Number Already Exist") {
            action.setFieldError("errorClientPhone", result.errMsg);
          }

          action.setSubmitting(false);
        } else {
          // action.resetForm();
          setIsOpen(false);
          setOpenPopup({
            ...openPopup,
            isOpen: false,
            title: "",
            subTitle: "",
          });
          dispatch({ type: CHEQUE_DETAILS_RESET });
          dispatch({ type: INVOICE_DETAILS_RESET });
          dispatch({ type: PAYMENT_HISTORY_DETAILS_RESET });
          setOrder("0");
          history.push("/payment/list");
        }
      });
    },
  });

  useEffect(() => {
    if (userInfo) {
      dispatch({ type: PAYMENT_DETAILS_RESET });
      dispatch(listPaymentDetails(paymentId));
      dispatch(listCustomersForSignup());

      dispatch(listInvoiceCheckKeyValue(order));

      dispatch(listPaymentMode(2));
      dispatch(listEmployeesForDropdown());
      dispatch(listChequeStatus());
      dispatch(listBank());
      dispatch(listChequeType());
      dispatch(listDesignation());
      dispatch(listChannel());
      dispatch(listDeparment());
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: `/payment/${paymentId}/edit` } },
      };
      history.push(location);
    }
  }, [paymentId, order, dispatch, history, userInfo]);

  const changeValues = () => {
    if (JSON.stringify(paymentSingle) !== "{}" && paymentSingle !== undefined) {
      var date = new Date(paymentSingle.paymentDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const paymentDate = [date.getFullYear(), mnth, day].join("-");

      var date = new Date(paymentSingle.chequeDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequeDate = [date.getFullYear(), mnth, day].join("-");

      var date = new Date(paymentSingle.chequeActionDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequeActionDate = [date.getFullYear(), mnth, day].join("-");

      // setOrder(
      //   paymentSingle.paymentChequeId === null
      //     ? paymentSingle.paymentRelatedJobId === null
      //       ? "2"
      //       : "0"
      //     : "1"
      // );

      setOrder(
        (paymentSingle.paymentMethodId === 4 ||  // If paymentMethodId is 4
        paymentSingle.paymentMethodId === 7 ||  // or 7
        paymentSingle.paymentMethodId === 8)    // or 8
          ? "3"  // If paymentMethodId is 4, 7, or 8, set order to "3"
          : (paymentSingle.paymentChequeId === null
              ? paymentSingle.paymentRelatedJobId === null
                ? "2"  // If both paymentChequeId and paymentRelatedJobId are null, set order to "2"
                : "0"  // If paymentChequeId is null but paymentRelatedJobId is not null, set order to "0"
              : "1")   // If paymentChequeId is not null, set order to "1"
      );

      formik.setFieldValue(
        "isInvoice",
        paymentSingle.paymentChequeId === null ? true : false
      );
      formik.setFieldValue("employee", {
        id:
          paymentSingle.paymentCollectedBy === 0
            ? ""
            : paymentSingle.paymentCollectedBy,

        label:
          paymentSingle.paymentCollectedBy === 0
            ? ""
            : paymentSingle.empFirstName !== null &&
              paymentSingle.empLastName !== null &&
              paymentSingle.employeeCode !== null
            ? paymentSingle.empFirstName +
                " " +
                paymentSingle.empLastName +
                "-" +
                paymentSingle.employeeCode || ""
            : "",
      });
    //  formik.setFieldValue(
    //     "paymentReference",
    //     paymentSingle.paymentChequeId === null
    //       ? paymentSingle.paymentRelatedJobId === null
    //         ? "2"  // If both paymentChequeId and paymentRelatedJobId are null, set paymentReference to "2"
    //         : "0"  // If paymentChequeId is null but paymentRelatedJobId is not null, set paymentReference to "0"
    //       : "1"    // If paymentChequeId is not null, set paymentReference to "1"
    //   );
    formik.setFieldValue(
      "paymentReference",
      (paymentSingle.paymentMethodId === 4 ||  // If paymentMethodId is 4
      paymentSingle.paymentMethodId === 7 ||  // or 7
      paymentSingle.paymentMethodId === 8)    // or 8
        ? "3"  // If paymentMethodId is 4, 7, or 8, set paymentReference to "3"
        : (paymentSingle.paymentChequeId === null
            ? paymentSingle.paymentRelatedJobId === null
              ? "2"  // If both paymentChequeId and paymentRelatedJobId are null, set paymentReference to "2"
              : "0"  // If paymentChequeId is null but paymentRelatedJobId is not null, set paymentReference to "0"
            : "1")   // If paymentChequeId is not null, set paymentReference to "1"
    );

      formik.setFieldValue("paymentMode", {
        id: paymentSingle.paymentMethodId || "",
        label: paymentSingle.paymentMethodName || "",
      });

      formik.setFieldValue("invoiceCheque", {
        id:
          paymentSingle.paymentChequeId === null
            ? paymentSingle.paymentRelatedJobId
            : paymentSingle.paymentChequeId || "",
        label:
          paymentSingle.paymentChequeId === null
            ? paymentSingle.soSystemNo
            : paymentSingle.chequeNumber || "",
      });
      formik.setFieldValue("customer", {
        id: paymentSingle.paymentCustomerId || "",
        label: paymentSingle.customerName || "",
      });
      formik.setFieldValue(
        "moneyReceiptNo",
        paymentSingle.moenyRecepitNo || ""
      );
      formik.setFieldValue("invoiceNumber", paymentSingle.soSystemNo || "");
      formik.setFieldValue("invoiceAmount", paymentSingle.sobFinalBill || "");

      formik.setFieldValue(
        "receiveAmount",
        paymentSingle.paymentChequeId === null
          ? paymentSingle.paymentAmount
          : paymentSingle.chequeAmount || ""
      );
      formik.setFieldValue(
        "adjustedAmount",
        paymentSingle.paymentAdjustedAmount
      );
      formik.setFieldValue(
        "adjustedAmountShow",
        paymentSingle.paymentAdjustedAmount
      );
      formik.setFieldValue("paymentDate", paymentDate || "");
      formik.setFieldValue("paymentNote", paymentSingle.paymentNote || "");
      formik.setFieldValue("chequeDate", chequeDate);
      formik.setFieldValue("chequeActionDate", chequeActionDate);
      formik.setFieldValue("chequeNumber", paymentSingle.chequeNumber || "");
      formik.setFieldValue(
        "receiveAmount",
        paymentSingle.chequeAmount !== null
          ? paymentSingle.chequeAmount
          : paymentSingle.paymentAmount || ""
      );

      formik.setFieldValue("bank", {
        id: paymentSingle.paymentBeneficiaryBankId || "",
        label: paymentSingle.paymentBankName || "",
      });

      if (paymentSingle.paymentChequeId === null) {
        dispatch(listInvoiceDetails(paymentSingle.paymentRelatedJobId));
      } else if (paymentSingle.paymentRelatedJobId === null) {
        dispatch(listChequeDetails(paymentSingle.paymentChequeId));
      }
    }
  };

  useEffect(() => {
    changeValues();
  }, [paymentSingle]);

  const changeInvoiceValues = () => {
    console.log("invoiceSingle");
    console.log(invoiceSingle);
    if (JSON.stringify(invoiceSingle) !== "{}" && invoiceSingle !== undefined) {
      console.log("I am here");
      formik.setFieldValue("invoiceNumber", invoiceSingle.soSystemNo || "");
      formik.setFieldValue("invoiceAmount", invoiceSingle.sobFinalBill);
      formik.setFieldValue("paidAmount", invoiceSingle.paidAmount);

      formik.setFieldValue("balanceAmount", invoiceSingle.balance);
      formik.setFieldValue("adjustedAmountShow", invoiceSingle.adjustedAmount);
      formik.setFieldValue("customer", {
        id: invoiceSingle.customerId || "",
        label: invoiceSingle.customerName || "",
      });
    }
  };

  useEffect(() => {
    changeInvoiceValues();
  }, [invoiceSingle]);

  const changeChequeValues = () => {
    if (JSON.stringify(chequeSingle) !== "{}" && chequeSingle !== undefined) {
      var date = new Date(chequeSingle.chequeCollectionDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequeCollectionDate = [date.getFullYear(), mnth, day].join("-");

      var date = new Date(chequeSingle.chequeDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequeDate = [date.getFullYear(), mnth, day].join("-");

      var date = new Date(chequeSingle.chequeExpireDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequeExpireDate = [date.getFullYear(), mnth, day].join("-");

      var date = new Date(chequeSingle.chequePlacementDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequePlacementDate = [date.getFullYear(), mnth, day].join("-");

      var date = new Date(chequeSingle.chequeActionDate),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      const chequeActionDate = [date.getFullYear(), mnth, day].join("-");

      formik.setFieldValue("chequeCollectionDate", chequeCollectionDate || "");
      formik.setFieldValue("chequeDate", chequeDate || "");
      formik.setFieldValue("chequeExpireDate", chequeExpireDate || "");
      formik.setFieldValue("chequePlacementDate", chequePlacementDate || "");
      formik.setFieldValue("chequeActionDate", chequeActionDate || "");
      formik.setFieldValue("chequeNumber", chequeSingle.chequeNumber || "");
      formik.setFieldValue("chequeAmount", chequeSingle.chequeAmount || "");
      //   formik.setFieldValue("employee", {
      //     id: chequeSingle.employeeId === 0 ? "" : chequeSingle.employeeId,

      //     label:
      //       chequeSingle.employeeId === 0
      //         ? ""
      //         : chequeSingle.empFirstName !== null &&
      //           chequeSingle.empLastName !== null &&
      //           chequeSingle.employeeCode !== null
      //         ? chequeSingle.empFirstName +
      //             " " +
      //             chequeSingle.empLastName +
      //             "-" +
      //             chequeSingle.employeeCode || ""
      //         : "",
      //   });
      formik.setFieldValue("chequeType", {
        id: chequeSingle.chequeMethodInfoId || "",
        label: chequeSingle.paymentMethodName || "",
      });
      formik.setFieldValue("customer", {
        id: chequeSingle.chequeCustomerId || "",
        label: chequeSingle.customerName || "",
      });
      formik.setFieldValue("bank", {
        id: chequeSingle.chequeBeneficiaryBankId || "",
        label: chequeSingle.beneficiaryBankName || "",
      });
      formik.setFieldValue("issuerBank", {
        id: chequeSingle.chequeIssuerBankId || "",
        label: chequeSingle.chequeBankName || "",
      });
      formik.setFieldValue("chequeStatus", {
        id: 2 || "",
        label: "Cleared" || "",
      });
    }
  };

  useEffect(() => {
    changeChequeValues();
  }, [chequeSingle]);

  const channelChange = useCallback((e, v) => {
    formik.setFieldValue("channel", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const designationChange = useCallback((e, v) => {
    formik.setFieldValue("designation", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const departmentChange = useCallback((e, v) => {
    formik.setFieldValue("department", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const employeeChange = useCallback((e, v) => {
    formik.setFieldValue("employee", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const chequeTypeChange = useCallback((e, v) => {
    formik.setFieldValue("chequeType", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const paymentModeChange = useCallback((e, v) => {
    formik.setFieldValue("paymentMode", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const invoiceChequeChange = useCallback((e, v) => {
    formik.setFieldValue("invoiceCheque", {
      id: v?.id || "",
      label: v?.label || "",
    });

    if (order === "0") {
      dispatch(listInvoiceDetails(v?.id));
    } else if (order === "1") {
      dispatch(listChequeDetails(v?.id));
    }
  });

  const customerChange = useCallback((e, v) => {
    formik.setFieldValue("customer", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const chequeStatusChange = useCallback((e, v) => {
    formik.setFieldValue("chequeStatus", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const bankChange = useCallback((e, v) => {
    formik.setFieldValue("bank", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const issuerBankChange = useCallback((e, v) => {
    formik.setFieldValue("issuerBank", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  const invoiceChequeCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const customerOptionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const chequeStatusOptionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const bankOptionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });

  const closeModal = (event) => {
    setIsOpen(false);
  };

  return (
    <div>
      <Menu />
      <PageHeader
        icon={<PersonAddIcon />}
        title="Payments"
        subtitle="Edit this Payment"
      />
      <Paper className={`${classes.pageContent} paperdesign1`}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingEmployee ? true : false}
                id="combo-box-demo-channel"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={employeeChange}
                value={formik.values.employee}
                options={employees !== undefined ? employees : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label="Collected By"
                    name="employee"
                    error={
                      formik.errors.employee !== undefined
                        ? formik.errors.employee.id
                        : ""
                    }
                    touched={
                      formik.touched.employee !== undefined
                        ? formik.touched.employee
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              <Select
                variant="outlined"
                size="small"
                label="Payment Type"
                name="paymentReference"
                error={formik.errors.paymentReference}
                touched={formik.touched.paymentReference}
                value={formik.values.paymentReference}
                onChange={(e) => {
                  formik.setFieldValue("invoiceCheque", {
                    id: "",
                    label: "",
                  });
                  formik.setFieldValue("paymentMode", {
                    id: "",
                    label: "",
                  });
                  formik.setFieldValue("customer", {
                    id: "",
                    label: "",
                  });
                  formik.setFieldValue("invoiceNumber", "");
                  formik.setFieldValue("paidAmount", "");
                  formik.setFieldValue("balanceAmount", "");
                  formik.setFieldValue("adjustedAmount", "");
                  formik.setFieldValue("adjustedAmountShow", "");
                  formik.setFieldValue("invoiceAmount", "");
                  formik.setFieldValue("receiveAmount", "");
                  formik.setFieldValue(
                    "paymentDate",
                    [date.getFullYear(), mnth, day].join("-")
                  );
                  formik.setFieldValue("paymentNote", "");
                  formik.setFieldValue(
                    "chequeDate",
                    [date.getFullYear(), mnth, day].join("-")
                  );
                  formik.setFieldValue(
                    "chequeActionDate",
                    [date.getFullYear(), mnth, day].join("-")
                  );
                  formik.setFieldValue("chequeNumber", "");
                  formik.setFieldValue("chequeAmount", "");
                  formik.setFieldValue("bank", {
                    id: "",
                    label: "",
                  });
                  formik.setFieldValue("chequeStatus", {
                    id: "",
                    label: "",
                  });
                  setIsOpen(false);
                  setOpenPopup({
                    ...openPopup,
                    isOpen: false,
                    title: "",
                    subTitle: "",
                  });
                  dispatch({ type: CHEQUE_DETAILS_RESET });
                  dispatch({ type: INVOICE_DETAILS_RESET });
                  dispatch({ type: PAYMENT_HISTORY_DETAILS_RESET });

                  formik.handleChange(e);
                  formik.setFieldValue(
                    "isInvoice",
                    e.target.value === "0" ? true : false
                  );

                  setOrder(e.target.value);
                }}
                onBlur={formik.handleBlur}
                options={[
                  {
                    id: "0",
                    title: "Invoice",
                  },
                  {
                    id: "1",
                    title: "Cheque",
                  },
                  {
                    id: "2",
                    title: "Open",
                  },
                  {
                    id: "3",
                    title: "Transfer",
                  },
                ]}
              />
              {order === "1" && (
                <Autocomplete
                  disablePortal
                  size="small"
                  disabled={loadingCustomer ? true : false}
                  id="combo-box-demo-channel"
                  isOptionEqualToValue={customerOptionCheck}
                  onChange={customerChange}
                  value={formik.values.customer}
                  options={customers !== undefined ? customers : []}
                  onOpen={formik.handleBlur}
                  filterOptions={filterOptions}
                  renderInput={(params) => (
                    <Input
                      label="Customer Name"
                      name="customer"
                      error={
                        formik.errors.customer !== undefined
                          ? formik.errors.customer.id
                          : ""
                      }
                      touched={
                        formik.touched.customer !== undefined
                          ? formik.touched.customer
                          : ""
                      }
                      {...params}
                      onBlur={formik.handleBlur}
                    />
                  )}
                />
              )}
              {(order === "0" || order === "2") && (
                <>
                  <Input
                    label="Money Receipt No"
                    name="moneyReceiptNo"
                    error={formik.errors.moneyReceiptNo}
                    touched={formik.touched.moneyReceiptNo}
                    value={formik.values.moneyReceiptNo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <Autocomplete
                    disablePortal
                    size="small"
                    disabled={loadingCustomer ? true : false}
                    id="combo-box-demo-channel"
                    isOptionEqualToValue={customerOptionCheck}
                    onChange={customerChange}
                    value={formik.values.customer}
                    options={customers !== undefined ? customers : []}
                    onOpen={formik.handleBlur}
                    filterOptions={filterOptions}
                    renderInput={(params) => (
                      <Input
                        label="Customer Name"
                        name="customer"
                        error={
                          formik.errors.customer !== undefined
                            ? formik.errors.customer.id
                            : ""
                        }
                        touched={
                          formik.touched.customer !== undefined
                            ? formik.touched.customer
                            : ""
                        }
                        {...params}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  />
                </>
              )}
              <Autocomplete
                disablePortal
                size="small"
                disabled={loadingPaymentMode ? true : false}
                id="combo-box-demo-channel"
                isOptionEqualToValue={useCallback(
                  (option, value) => option.value === value.value
                )}
                onChange={paymentModeChange}
                value={formik.values.paymentMode}
                options={paymentmodes !== undefined ? paymentmodes : []}
                onOpen={formik.handleBlur}
                renderInput={(params) => (
                  <Input
                    label="Payment Mode"
                    name="paymentMode"
                    error={
                      formik.errors.paymentMode !== undefined
                        ? formik.errors.paymentMode.id
                        : ""
                    }
                    touched={
                      formik.touched.paymentMode !== undefined
                        ? formik.touched.paymentMode
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />

            {order === "3" && (
                <>
                <Autocomplete
                  disablePortal
                  size="small"
                  disabled={loadingCustomer ? true : false}
                  id="combo-box-demo-channel"
                  isOptionEqualToValue={customerOptionCheck}
                  onChange={customerChange}
                  value={formik.values.customer}
                  options={customers !== undefined ? customers : []}
                  onOpen={formik.handleBlur}
                  filterOptions={filterOptions}
                  renderInput={(params) => (
                    <Input
                      label="Customer Name"
                      name="customer"
                      error={
                        formik.errors.customer !== undefined
                          ? formik.errors.customer.id
                          : ""
                      }
                      touched={
                        formik.touched.customer !== undefined
                          ? formik.touched.customer
                          : ""
                      }
                      {...params}
                      onBlur={formik.handleBlur}
                    />
                  )}
                />
                
                  <Autocomplete
                    disablePortal
                    size="small"
                    disabled={loadingBanks ? true : false}
                    id="combo-box-demo-channel"
                    isOptionEqualToValue={bankOptionCheck}
                    onChange={bankChange}
                    value={formik.values.bank}
                    options={banks !== undefined ? banks : []}
                    onOpen={formik.handleBlur}
                    renderInput={(params) => (
                      <Input
                        label="Beneficiary Bank"
                        name="bank"
                        error={
                          formik.errors.bank !== undefined
                            ? formik.errors.bank.id
                            : ""
                        }
                        touched={
                          formik.touched.bank !== undefined
                            ? formik.touched.bank
                            : ""
                        }
                        {...params}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  />
                  <Input
                    label="Receive Amount"
                    name="receiveAmount"
                    error={formik.errors.receiveAmount}
                    touched={formik.touched.receiveAmount}
                    value={formik.values.receiveAmount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <Datepicker
                    label="Payment Date"
                    name="paymentDate"
                    value={formik.values.paymentDate}
                    onChange={formik.handleChange}
                  />
                  <Input
                    label="Payment Note"
                    name="paymentNote"
                    error={formik.errors.paymentNote}
                    touched={formik.touched.paymentNote}
                    value={formik.values.paymentNote}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    multiple
                  />
                </>
              )}



              {order === "" || order === "2" || order === "3" ? (
                <></>
              ) : (
                <Autocomplete
                  disablePortal
                  size="small"
                  disabled={loadingInvoiceCheque ? true : false}
                  id="combo-box-demo-channel"
                  isOptionEqualToValue={invoiceChequeCheck}
                  onChange={invoiceChequeChange}
                  value={formik.values.invoiceCheque}
                  options={invoicecheques !== undefined ? invoicecheques : []}
                  onOpen={formik.handleBlur}
                  renderInput={(params) => (
                    <Input
                      label={order === "0" ? "Invoice No" : "Cheque No"}
                      name="invoiceCheque"
                      error={
                        formik.errors.invoiceCheque !== undefined
                          ? formik.errors.invoiceCheque.id
                          : ""
                      }
                      touched={
                        formik.touched.invoiceCheque !== undefined
                          ? formik.touched.invoiceCheque
                          : ""
                      }
                      {...params}
                      onBlur={formik.handleBlur}
                    />
                  )}
                />
              )}
              {order === "0" && (
                <div>
                  <MuiButton
                    size="small"
                    startIcon={
                      loadingPaymentHistoryDetails ? (
                        <CircularProgress size="1rem" color="error" />
                      ) : (
                        <></>
                      )
                    }
                    onClick={() => {
                      if (formik.values.invoiceCheque.id !== "") {
                        dispatch(
                          listPaymentHistoryDetails(
                            formik.values.invoiceCheque.id
                          )
                        ).then((res) => {
                          if (res !== undefined && res.length > 0) {
                            setIsOpen(true);
                          } else {
                            setOpenPopup({
                              ...openPopup,
                              isOpen: true,
                              title: "EMPTY HISTORY!!",
                              subTitle: "No history found!",
                            });
                          }
                        });
                      } else {
                        setOpenPopup({
                          ...openPopup,
                          isOpen: true,
                          title: "NO INVOICE SELECTED!!",
                          subTitle: "Please select an invoice!",
                        });
                      }
                    }}
                  >
                    view
                  </MuiButton>
                </div>
              )}
              {order === "0" && (
                <Input
                  label="Adjusted Amount"
                  name="adjustedAmount"
                  error={formik.errors.adjustedAmount}
                  touched={formik.touched.adjustedAmount}
                  value={formik.values.adjustedAmount}
                  //value=""//shahed
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  InputProps={
                    {
                      //Comented by shahed
                      // endAdornment: (
                      //   <>
                      //     <RadioGroup
                      //       value={value}
                      //       onChange={onChange}
                      //       items={[
                      //         {
                      //           id: "+",
                      //           title: "+",
                      //         },
                      //         {
                      //           id: "-",
                      //           title: "-",
                      //         },
                      //       ]}
                      //     />
                      //   </>
                      //   // <InputAdornment position="end">
                      //   //   {/* <FormLabel>{label}</FormLabel> */}
                      //   // </InputAdornment>
                      // ),
                    }
                  }
                />
              )}
              {order === "2" && (
                <>
                  <Input
                    label="Receive Amount"
                    name="receiveAmount"
                    error={formik.errors.receiveAmount}
                    touched={formik.touched.receiveAmount}
                    value={formik.values.receiveAmount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <Datepicker
                    label="Payment Date"
                    name="paymentDate"
                    value={formik.values.paymentDate}
                    onChange={formik.handleChange}
                  />
                  <Input
                    label="Payment Note"
                    name="paymentNote"
                    error={formik.errors.paymentNote}
                    touched={formik.touched.paymentNote}
                    value={formik.values.paymentNote}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    multiple
                  />
                </>
              )}
              {order === "0" &&
                JSON.stringify(invoiceSingle) !== "{}" &&
                invoiceSingle !== undefined && (
                  <>
                    <Input
                      label="Receive Amount"
                      name="receiveAmount"
                      error={formik.errors.receiveAmount}
                      touched={formik.touched.receiveAmount}
                      value={formik.values.receiveAmount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <Datepicker
                      label="Payment Date"
                      name="paymentDate"
                      value={formik.values.paymentDate}
                      onChange={formik.handleChange}
                    />
                    <Input
                      label="Payment Note"
                      name="paymentNote"
                      error={formik.errors.paymentNote}
                      touched={formik.touched.paymentNote}
                      value={formik.values.paymentNote}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      multiple
                    />
                  </>
                )}

              {order === "1" &&
                JSON.stringify(chequeSingle) !== "{}" &&
                chequeSingle !== undefined && (
                  <>
                    <Input
                      label="Receive Amount"
                      name="receiveAmount"
                      error={formik.errors.receiveAmount}
                      touched={formik.touched.receiveAmount}
                      value={formik.values.receiveAmount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <Datepicker
                      label="Payment Date"
                      name="paymentDate"
                      value={formik.values.paymentDate}
                      onChange={formik.handleChange}
                    />
                    <Autocomplete
                      disablePortal
                      size="small"
                      disabled={loadingBanks ? true : false}
                      id="combo-box-demo-channel"
                      isOptionEqualToValue={bankOptionCheck}
                      onChange={bankChange}
                      value={formik.values.bank}
                      options={banks !== undefined ? banks : []}
                      onOpen={formik.handleBlur}
                      renderInput={(params) => (
                        <Input
                          label="Beneficiary Bank"
                          name="bank"
                          error={
                            formik.errors.bank !== undefined
                              ? formik.errors.bank.id
                              : ""
                          }
                          touched={
                            formik.touched.bank !== undefined
                              ? formik.touched.bank
                              : ""
                          }
                          {...params}
                          onBlur={formik.handleBlur}
                        />
                      )}
                    />
                    <Datepicker
                      label="Cheque Action Date"
                      name="chequeActionDate"
                      value={formik.values.chequeActionDate}
                      onChange={formik.handleChange}
                    />
                    {/* <Autocomplete
                      disablePortal
                      size="small"
                      disabled={true}
                      id="combo-box-demo-channel"
                      isOptionEqualToValue={chequeStatusOptionCheck}
                      onChange={chequeStatusChange}
                      value={formik.values.chequeStatus}
                      options={chequestatuss !== undefined ? chequestatuss : []}
                      onOpen={formik.handleBlur}
                      renderInput={(params) => (
                        <Input
                          label="chequestatus"
                          name="chequeStatus"
                          error={
                            formik.errors.chequeStatus !== undefined
                              ? formik.errors.chequeStatus.id
                              : ""
                          }
                          touched={
                            formik.touched.chequeStatus !== undefined
                              ? formik.touched.chequeStatus
                              : ""
                          }
                          {...params}
                          onBlur={formik.handleBlur}
                        />
                      )}
                    /> */}
                  </>
                )}
            </Grid>
            <Grid item xs={12} md={6}>
              {order === "0" &&
                JSON.stringify(invoiceSingle) !== "{}" &&
                invoiceSingle !== undefined && (
                  <>
                    <Input
                      label="Invoice Number"
                      name="invoiceNumber"
                      error={formik.errors.invoiceNumber}
                      touched={formik.touched.invoiceNumber}
                      value={formik.values.invoiceNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                    <Input
                      label="Invoice Amount"
                      name="invoiceAmount"
                      error={formik.errors.invoiceAmount}
                      touched={formik.touched.invoiceAmount}
                      value={formik.values.invoiceAmount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                    <Input
                      label="paid Amount"
                      name="paidAmount"
                      error={formik.errors.paidAmount}
                      touched={formik.touched.paidAmount}
                      value={formik.values.paidAmount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                    <Input
                      label="Adjusted Amount"
                      name="adjustedAmountShow"
                      error={formik.errors.adjustedAmountShow}
                      touched={formik.touched.adjustedAmountShow}
                      value={formik.values.adjustedAmountShow}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                    <Input
                      label="Balance Amount"
                      name="balanceAmount"
                      error={formik.errors.balanceAmount}
                      touched={formik.touched.balanceAmount}
                      value={formik.values.balanceAmount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                  </>
                )}

              {order === "1" &&
                JSON.stringify(chequeSingle) !== "{}" &&
                chequeSingle !== undefined && (
                  <>
                    <Autocomplete
                      disablePortal
                      size="small"
                      disabled
                      id="combo-box-demo-channel"
                      isOptionEqualToValue={customerOptionCheck}
                      onChange={customerChange}
                      value={formik.values.customer}
                      options={customers !== undefined ? customers : []}
                      onOpen={formik.handleBlur}
                      filterOptions={filterOptions}
                      renderInput={(params) => (
                        <Input
                          label="Customer Name"
                          name="customer"
                          error={
                            formik.errors.customer !== undefined
                              ? formik.errors.customer.id
                              : ""
                          }
                          touched={
                            formik.touched.customer !== undefined
                              ? formik.touched.customer
                              : ""
                          }
                          {...params}
                          onBlur={formik.handleBlur}
                        />
                      )}
                    />
                    <Input
                      label="Cheque Number"
                      name="chequeNumber"
                      error={formik.errors.chequeNumber}
                      touched={formik.touched.chequeNumber}
                      value={formik.values.chequeNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                    <Input
                      label="Cheque Amount"
                      name="chequeAmount"
                      error={formik.errors.chequeAmount}
                      touched={formik.touched.chequeAmount}
                      value={formik.values.chequeAmount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />
                    <Datepicker
                      label="Cheque Date"
                      name="chequeDate"
                      value={formik.values.chequeDate}
                      onChange={formik.handleChange}
                      disabled
                    />
                  </>
                )}
            </Grid>

            <Grid item xs={12} md={12}>
              <ButtonGroup className={classes.submitButtonGroup} type="submit">
                <MuiButton
                  endIcon={
                    loadingUpdate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      <PublishIcon />
                    )
                  }
                  size="large"
                  type="submit"
                >
                  Update
                </MuiButton>
                <MuiButton
                  size="small"
                  startIcon={<ReplayIcon />}
                  onClick={() => {
                    formik.resetForm();
                    setChecked(true);
                  }}
                />
              </ButtonGroup>
              <Button
                color="error"
                text="Back"
                onClick={() => {
                  const location = {
                    pathname: "/payment/list",
                    state: {},
                  };
                  history.push(location);
                }}
              />
            </Grid>
          </Grid>
        </Form>
        <div
          className="paperdesign2"
          style={{
            display:
              loadingChannels && loadingDesignations && loadingDepartments
                ? ""
                : "none",
          }}
        >
          <div>
            <Circles
              height="70"
              width="100"
              color="#F3EC19"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              // wrapperClass=""
              // style={{
              //   width: "100%",
              //   height: "100%",
              //   display: "flex !important",
              //   justifyContent: "center",
              //   alignItems: "center",
              // }}
              visible={true}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "white",
              }}
            >
              Please wait
            </p>
          </div>
        </div>
      </Paper>
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
      {JSON.stringify(paymentHistoryList) !== "[]" &&
        paymentHistoryList !== undefined && (
          <PaymentHistoryView
            showModal={isOpen}
            closeModal={closeModal}
            info={paymentHistoryList}
          />
        )}
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default withRouter(EditPayment);
