import React, { useCallback, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  Grid,
  Paper,
  Button as MuiButton,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Menu from "../../../core/Menu";
import PageHeader from "../../../core/PageHeader";
import { Form } from "../../../core/useForm";
import Input from "../../../core/controls/Input";
import Datepicker from "../../../core/controls/Datepicker";
import Button from "../../../core/controls/Button";
import { useEffect } from "react";
import { EMPLOYEE_CREATE_RESET } from "../../../redux/constants/employeeConstants";
import Notification from "../../../core/Notification";
import { listCustomersForSignup } from "../../../redux/actions/userActions";
import { API_URL } from "../../../redux/constants/apiConstants";
import Popup from "../../../core/Popup";
import { getProductsList } from "../../../redux/actions/CurrentStockReportActions";
import { listSuppliersForDropdown } from "../../../redux/actions/supplierActions";
import axios from "axios";

import { exportToExcelExcelJs } from "../../../utils/json-to-excel-export-exceljs";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    backgroundColor: "#e0e0e0", // Light ash color
    margin: useTheme().spacing(1),
    padding: useTheme().spacing(2),
    [useTheme().breakpoints.up("md")]: {
      margin: useTheme().spacing(5),
      padding: useTheme().spacing(3),
    },
  },
  adornmentText: {
    "& .MuiTypography-root": {
      color: "#f3b33d",
      fontWeight: "bolder",
      fontSize: "1.5rem",
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);

const initialValues = {
  from: [date.getFullYear(), mnth, day].join("-"),
  to: [date.getFullYear(), mnth, day].join("-"),
  supplier:{
    id: "",
    label: "",
  },
  customer:{
    id: "",
    label: "",
  },
  customerName: {
    id: "",
    label: "",
  },
  productName: {
    id: "",
    label: "",
  },
};

const BatchReportForStockIn = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const csvDownloadRef = useRef(0);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [checked, setChecked] = useState(true);
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const productsNameList = useSelector((state) => state.productNameList);
  const {
    loading: loadingProductsName,
    error: errorProductsName,
    productNameList,
  } = productsNameList;

  const supplierDropdownList = useSelector((state) => state.supplierDropdownList);
  const {
    loading: loadingSuppliers,
    error: errorSupplier,
    suppliers: suppliersDropdown,
  } = supplierDropdownList;

  const employeeCreate = useSelector((state) => state.employeeCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    employee: createdProduct,
  } = employeeCreate;

  const userLogin = useSelector((state) => state.userLogin);

  const { userInfo } = userLogin;

  const heading_with_title = {
    siSystemNo: "System Id",
    siInvoiceNo: "Invoice No",
    siInvoiceDate: "Invoice Date",
    supplierId: "Customer Id",
    supplierName: "Customer Name",
    productId: "Product Id",
    productName: "Product Name",
    batchNo: "Batch Id",
    
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, action) => {
      const axios_config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };
  
    const supplierId = values.supplier?.id || "0";
    const productId = values.productName?.id || "0";
    const api_endpoint = `${API_URL}/Report/GetAllStockInBatchReport?supplierId=${supplierId}&productId=${productId}`;

    const response = await axios.get(api_endpoint, axios_config);
    const { data } = response;

      if (response?.errMsg) {
      } else {
        if (data?.dataObj?.length > 0) {
          exportToExcelExcelJs(
            data?.dataObj,
            heading_with_title,
            `StockOut_Batch_Report_Data_${
              (supplierId === "0" && productId > "0")
                ? "All_Supplliers"
                : (supplierId > "0" && productId === "0")
                ? data?.dataObj[0]?.supplierName
                : (supplierId > "0" && productId > "0")
                ? data?.dataObj[0]?.supplierName
                : "All_Suppliers"
            }`,

            `Batch Stock Out Report For ${
              (supplierId === "0" && productId > "0")
                ? "All Suppliers (Product: " + data?.dataObj[0]?.supplierName + ")"
                : (supplierId > "0" && productId === "0")
                ? data?.dataObj[0]?.supplierName + " (All Products)"
                : (supplierId > "0" && productId > "0")
                ? data?.dataObj[0]?.supplierName
                : "All Suppliers and All Products"
            }`

          );
        } else {
          action.setSubmitting(false);
          setOpenPopup({
            ...openPopup,
            title: "ZERO DATA!!!",
            subTitle: "No Data found with this specifications!",
            isOpen: true,
          });
        }
      }
    },
  });

  useEffect(() => {
    if (userInfo) {
     dispatch(getProductsList());
     dispatch(listSuppliersForDropdown());
      if (successCreate && checked) {
        setNotify({
          isOpen: true,
          message: "Added Successfully",
          type: "success",
        });

        dispatch({ type: EMPLOYEE_CREATE_RESET });
      }
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/report/mapped" } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo, successCreate]);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });
  
      const supplierChange = useCallback((e, v) => {
        // Update supplier ID and label in formik values
        formik.setFieldValue("supplier", {
          id: v?.id || "",  // Set ID from selected supplier
          label: v?.label || "",
        });
      });
          
 // Handle product name change in Autocomplete
 const productNameChange = useCallback((e, v) => {
  formik.setFieldValue("productName", {
    id: v?.id || "",
    label: v?.label || "",
  });
});
  const supplierOptionCheck = useCallback(
    (option, value) => option.value === value.value
  );

  return (
    <div>
      <Menu />
      <PageHeader
        // icon={<AssessmentIcon />}
        icon={<AssessmentIcon style={{ color: "#FF8C00", fontSize: "50px" }} />}
        title={
          <span style={{ color: "#8B4000", fontWeight: "bold",  fontSize: "24px" }}>
            Stock In Batch Report
          </span>
        }
        subtitle="Get In Batch Report"
      />
      <Paper className={classes.pageContent}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
          
            <Grid item xs={12} md={6}>
              <Autocomplete
                //disablePortal
                size="small"
                disabled={loadingSuppliers ? true : false}
                id="combo-box-demo1"
                isOptionEqualToValue={supplierOptionCheck}
                onChange={(e, v) => {
                    supplierChange(e, v);
                }}
                value={formik.values.supplier}
                options={suppliersDropdown !== undefined ? suppliersDropdown : []}
                onOpen={formik.handleBlur}
                filterOptions={filterOptions}
                renderInput={(params) => (
                  <Input
                    label="Supplier Name"
                    name="supplier"
                    error={
                      formik.errors.supplier !== undefined
                        ? formik.errors.supplier.id
                        : ""
                    }
                    touched={
                      formik.touched.supplier !== undefined
                        ? formik.touched.supplier
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                   
                  />
                )}
                freeSolo={true}
              />
              {/* Autocomplete for Product Name */}
              <Autocomplete
                size="small"
                disabled={loadingProductsName ? true : false}
                id="combo-box-demo-channel"
                onChange={productNameChange}
                value={formik.values.productName}
                options={productNameList !== undefined ? productNameList : []}
                onOpen={formik.handleBlur}
                filterOptions={filterOptions}
                renderInput={(params) => (
                  <Input
                    label="Product Name"
                    name="customer"
                    error={
                      formik.errors.customer !== undefined
                        ? formik.errors.customer.id
                        : ""
                    }
                    touched={
                      formik.touched.customer !== undefined
                        ? formik.touched.customer
                        : ""
                    }
                    {...params}
                    onBlur={formik.handleBlur}
                  />
                )}
              />

            </Grid>
            <Grid item xs={12} md={12}>
              <MuiButton
                className={classes.submitButtonGroup}
                endIcon={
                  loadingCreate ? (
                    <CircularProgress size="1rem" color="error" />
                  ) : null
                }
                size="large"
                type="submit"
              >
                <img
                  width={50}
                  height={50}
                  src={process.env.PUBLIC_URL + "/images/excel-icon.png"}
                  alt=""
                />
              </MuiButton>
            </Grid>
          </Grid>
        </Form>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
    </div>
  );
};

export default withRouter(BatchReportForStockIn);
