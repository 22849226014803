// styles.js
import { makeStyles, useTheme } from "@material-ui/core/styles";


const useStyles = makeStyles(() => ({
    colHeader: {
      height: 550,
  
      [useTheme().breakpoints.up("md")]: {
        maxWidth: "2000px",
        margin: "0 auto",
        padding: "0 60px",
      },
  
      "& .MuiDataGrid-root": {
        border: "none",
        overflowX: "auto",
      },
  
      "& .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
        margin: "auto",
      },
  
      "& .MuiDataGrid-columnHeaders": {
        backgroundColor: "#888885",
        color: "#fff",
        borderBottom: "none",
      },
  
      "& .MuiDataGrid-columnHeader": {
        minWidth: "290px !important",
        maxWidth: "290px !important",
      },
  
      "& .MuiDataGrid-cell": {
        minWidth: "290px !important",
        maxWidth: "290px !important",
        borderBottom: "none",
        textAlign: "center",
      },
  
      "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within ": {
        outline: "none !important",
        
      },
  
      "& .name-column--cell": {
        color: "#6C4AB6",
      },
      "& .MuiDataGrid-cell:last-child": {
        [useTheme().breakpoints.up("md")]: {
          minWidth: "0px !important",
          maxWidth: "0px !important",
        },
      },
  
      "& .MuiDataGrid-virtualScroller": {
        width: "2090px",
        overflowX: "scroll !important",
      },
  
      "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
        height: "6px",
        width: "6px",
        backgroundColor: "#F5F5F5",
      },
  
      "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        backgroundColor: "#B9B9B9",
      },
  
      "& .MuiDataGrid-columnHeaderCheckbox, .MuiDataGrid-cellCheckbox": {
        minWidth: "60px !important",
        maxWidth: "60px !important",
      },
  
      "& .MuiDataGrid-columnHeader[data-field='soSystemId'], .MuiDataGrid-cell[data-field='soSystemId']":
        {
          display: "none",
        },
      "& .MuiDataGrid-columnHeader[data-field='actions'], .MuiDataGrid-cell[data-field='actions'], .MuiDataGrid-columnHeader[data-field='actions2'], .MuiDataGrid-cell[data-field='actions2'], .MuiDataGrid-columnHeader[data-field='actions3'], .MuiDataGrid-cell[data-field='actions3'], .MuiDataGrid-columnHeader[data-field='actions4'], .MuiDataGrid-cell[data-field='actions4']":
        {
          minWidth: "100px !important",
          maxWidth: "100px !important",
        },
  
      "& .MuiDataGrid-footerContainer": {
        borderTop: "none",
        backgroundColor: "#888885",
      },
      "& .MuiDataGrid-footerContainer .MuiTablePagination-root": {
        color: "#fff",
      },
      "& .MuiDataGrid-footerContainer .MuiTablePagination-root .MuiSvgIcon-root":
        {
          color: "#fff",
        },
  
      "& .MuiButtonBase-root .MuiCheckbox-root": {
        backgroundColor: "#A4A9FC !important",
        color: "#b7ebde !important",
      },
    },
    submitButtonGroup: {
      backgroundColor: "#FF8C00",
      color: "#fff",
      margin: `${useTheme().spacing(0)} ${useTheme().spacing(1)}`,
      "& .MuiButtonBase-root": {
        textTransform: "none",
        color: "#fff",
        border: "2px solid #FF8C00",
      },
      "&:hover": {
        backgroundColor: "#FFA07A",
      },
    },
    pageContent: {
      overflowX: "auto",
      marginLeft: "auto",
      marginTop: useTheme().spacing(0),
      padding: useTheme().spacing(3),
    },
    pageContent2: {
      overflowX: "auto",
      justifyContent: "space-between",
      margin: useTheme().spacing(1),
      padding: `${useTheme().spacing(2)} ${useTheme().spacing(1)}`,
      [useTheme().breakpoints.up("md")]: {
        padding: `${useTheme().spacing(2)} ${useTheme().spacing(7)}`,
      },
      "& .MuiFormControl-root": {
        width: "100%",
      },
    },
    searchInput: {
      width: "50%",
      [useTheme().breakpoints.up("md")]: {
        width: "30% !important",
      },
    },
    toolBar: {
      display: "none",
      justifyContent: "center !important",
      flexWrap: "wrap",
      paddingRight: "0px !important",
      paddingLeft: "0px !important",
      "& div:nth-child(1)": {
        margin: "15px",
      },
      [useTheme().breakpoints.up("md")]: {
        display: "none",
        justifyContent: "center !important",
        maxWidth: "2000px !important",
        margin: "0 auto !important",
        padding: "0 60px !important",
        "& div:nth-child(1)": {
          margin: "0px",
        },
      },
      "& .MuiButtonBase-root": {
        [useTheme().breakpoints.down("md")]: {
          width: "30% !important",
          fontSize: "smaller !important",
        },
      },
      //
    },
  
    wrapperWhite: {
      backgroundColor: "#fff",
      padding: "2px",
    },
  
    actions: {
      display: "flex",
      justifyContent: "space-between",
      width: "500px",
      padding: "0px !important",
      [useTheme().breakpoints.up("md")]: {
        display: "flex",
        justifyContent: "space-between",
        width: "420px",
      },
    },
    actions2: {
      display: "flex",
      justifyContent: "space-between",
      width: "500px",
      padding: "0px !important",
      [useTheme().breakpoints.up("md")]: {
        display: "flex",
        justifyContent: "right",
        width: "420px",
      },
    },
  }));
  
export default useStyles;
