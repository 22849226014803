import {
    LC_PROFORMA_INVOICE_LIST_REQUEST,
    LC_PROFORMA_INVOICE_LIST_SUCCESS,
    LC_PROFORMA_INVOICE_LIST_FAIL,
    LC_PROFORMA_INVOICE_DETAILS_REQUEST,
    LC_PROFORMA_INVOICE_DETAILS_SUCCESS,
    LC_PROFORMA_INVOICE_DETAILS_FAIL,
    LC_PROFORMA_INVOICE_CREATE_REQUEST,
    LC_PROFORMA_INVOICE_CREATE_SUCCESS,
    LC_PROFORMA_INVOICE_CREATE_FAIL,
    LC_PROFORMA_INVOICE_UPDATE_REQUEST,
    LC_PROFORMA_INVOICE_UPDATE_SUCCESS,
    LC_PROFORMA_INVOICE_UPDATE_FAIL,
    LC_PROFORMA_INVOICE_DELETE_REQUEST,
    LC_PROFORMA_INVOICE_DELETE_SUCCESS,
    LC_PROFORMA_INVOICE_DELETE_FAIL,
    LC_LIFECYCLE_LIST_REQUEST,
    LC_LIFECYCLE_LIST_SUCCESS,
    LC_LIFECYCLE_LIST_FAIL,
    LC_PAYMENT_TERMS_LIST_REQUEST,
    LC_PAYMENT_TERMS_LIST_SUCCESS,
    LC_PAYMENT_TERMS_LIST_FAIL,
    LC_DOCUMENT_LIST_REQUEST,
    LC_DOCUMENT_LIST_SUCCESS,
    LC_DOCUMENT_LIST_FAIL,
    LC_DOCUMENT_DETAILS_REQUEST,
    LC_DOCUMENT_DETAILS_SUCCESS,
    LC_DOCUMENT_DETAILS_FAIL,
    LC_DOCUMENT_CREATE_REQUEST,
    LC_DOCUMENT_CREATE_SUCCESS,
    LC_DOCUMENT_CREATE_FAIL,
    LC_DOCUMENT_UPDATE_REQUEST,
    LC_DOCUMENT_UPDATE_SUCCESS,
    LC_DOCUMENT_UPDATE_FAIL,
    LC_DOCUMENT_DELETE_REQUEST,
    LC_DOCUMENT_DELETE_SUCCESS,
    LC_DOCUMENT_DELETE_FAIL,
  } from "../constants/LcConstans";
  
  // Proforma Invoice List Reducer
  export const proformaInvoiceListReducer = (state = { proformaInvoices: [] }, action) => {
    switch (action.type) {
      case LC_PROFORMA_INVOICE_LIST_REQUEST:
        return { loading: true };
      case LC_PROFORMA_INVOICE_LIST_SUCCESS:
        return { loading: false, proformaInvoices: action.payload };
      case LC_PROFORMA_INVOICE_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // Proforma Invoice Details Reducer
  export const proformaInvoiceDetailsReducer = (state = { proformaInvoice: {} }, action) => {
    switch (action.type) {
      case LC_PROFORMA_INVOICE_DETAILS_REQUEST:
        return { loading: true, ...state };
      case LC_PROFORMA_INVOICE_DETAILS_SUCCESS:
        return { loading: false, proformaInvoice: action.payload };
      case LC_PROFORMA_INVOICE_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // Proforma Invoice Create Reducer
  export const proformaInvoiceCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case LC_PROFORMA_INVOICE_CREATE_REQUEST:
        return { loading: true };
      case LC_PROFORMA_INVOICE_CREATE_SUCCESS:
        return { loading: false, success: true, proformaInvoice: action.payload };
      case LC_PROFORMA_INVOICE_CREATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // Proforma Invoice Update Reducer
  export const proformaInvoiceUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case LC_PROFORMA_INVOICE_UPDATE_REQUEST:
        return { loading: true };
      case LC_PROFORMA_INVOICE_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case LC_PROFORMA_INVOICE_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // Proforma Invoice Delete Reducer
  export const proformaInvoiceDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case LC_PROFORMA_INVOICE_DELETE_REQUEST:
        return { loading: true };
      case LC_PROFORMA_INVOICE_DELETE_SUCCESS:
        return { loading: false, success: true };
      case LC_PROFORMA_INVOICE_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // LC Lifecycle List Reducer
  export const lifecycleListReducer = (state = { lifecycles: [] }, action) => {
    switch (action.type) {
      case LC_LIFECYCLE_LIST_REQUEST:
        return { loading: true };
      case LC_LIFECYCLE_LIST_SUCCESS:
        return { loading: false, lifecycles: action.payload };
      case LC_LIFECYCLE_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // LC Payment Terms List Reducer
  export const paymentTermsListReducer = (state = { paymentTerms: [] }, action) => {
    switch (action.type) {
      case LC_PAYMENT_TERMS_LIST_REQUEST:
        return { loading: true };
      case LC_PAYMENT_TERMS_LIST_SUCCESS:
        return { loading: false, paymentTerms: action.payload };
      case LC_PAYMENT_TERMS_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // Document List Reducer
  export const documentListReducer = (state = { documents: [] }, action) => {
    switch (action.type) {
      case LC_DOCUMENT_LIST_REQUEST:
        return { loading: true };
      case LC_DOCUMENT_LIST_SUCCESS:
        return { loading: false, documents: action.payload };
      case LC_DOCUMENT_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // Document Details Reducer
  export const documentDetailsReducer = (state = { document: {} }, action) => {
    switch (action.type) {
      case LC_DOCUMENT_DETAILS_REQUEST:
        return { loading: true, ...state };
      case LC_DOCUMENT_DETAILS_SUCCESS:
        return { loading: false, document: action.payload };
      case LC_DOCUMENT_DETAILS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // Document Create Reducer
  export const documentCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case LC_DOCUMENT_CREATE_REQUEST:
        return { loading: true };
      case LC_DOCUMENT_CREATE_SUCCESS:
        return { loading: false, success: true, document: action.payload };
      case LC_DOCUMENT_CREATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // Document Update Reducer
  export const documentUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case LC_DOCUMENT_UPDATE_REQUEST:
        return { loading: true };
      case LC_DOCUMENT_UPDATE_SUCCESS:
        return { loading: false, success: true };
      case LC_DOCUMENT_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  // Document Delete Reducer
  export const documentDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case LC_DOCUMENT_DELETE_REQUEST:
        return { loading: true };
      case LC_DOCUMENT_DELETE_SUCCESS:
        return { loading: false, success: true };
      case LC_DOCUMENT_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  