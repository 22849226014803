import ExcelJs from "exceljs";

export const exportToExcelExcelJs = (
  data = [],
  headerKeyMapping,
  file_name = "Report",
  excel_header = "Report",
  titleInfo = {} // New parameter to accept title details like customerId, customerName, etc.
) => {
  let sheetName = `${file_name}.xlsx`;
  let headerName = "RequestsList";

  let workbook = new ExcelJs.Workbook();
  let sheet = workbook.addWorksheet(sheetName, {
    views: [{ showGridLines: true }],
  });

    // Add the title with specific formatting
  const titleRow = sheet.addRow(["Customer Credit History"]);
  titleRow.font = { size: 16, bold: true }; // Set font size to 16 and bold

  // Merge the title row across columns (assuming 6 columns, adjust as necessary)
  sheet.mergeCells(`A1:H1`); // Adjust range as needed (A1 to F1 in this case)
  titleRow.alignment = { horizontal: 'center' }; // Center the title

  sheet.addRow([]);
     
  // Default row alignment for the sheet
  sheet.properties.defaultRowAlignment = {
    vertical: "bottom",
    horizontal: "left",  // Set default horizontal alignment to left
  };

  // Define the titles with smaller font size and no bold formatting
  sheet.addRow([`Customer Id: ${titleInfo.customerId || ""}`]).font = { size: 13, bold: true };
  sheet.addRow([`Customer Name: ${titleInfo.customerName || ""}`]).font = { size: 13, bold: true };
  sheet.addRow([`Customer Address: ${titleInfo.customerAddress || ""}`]).font = { size: 13, bold: true };
  sheet.addRow([`Report Range: ${titleInfo.reportRange || ""}`]).font = { size: 13, bold: true };

  // Add a blank row for spacing before the data table starts
  sheet.addRow([]);

  let updatedTitle = [];
  for (const columnDataKey in headerKeyMapping) {
    updatedTitle.push({ name: headerKeyMapping[columnDataKey] });
  }

  // Add the table header
  sheet.addTable({
    name: `Header`,
    ref: "A6",  // Adjusting the reference since title rows are added
    headerRow: true,
    totalsRow: false,
    style: {
      theme: "",
      showRowStripes: false,
      showFirstColumn: true,
      width: 200,
    },
    columns: [{ name: excel_header }],
    rows: [[]],
  });

  // Add the data table
  sheet.addTable({
    name: headerName,
    ref: "A8",  // Adjusting reference to reflect row position after title rows
    headerRow: true,
    totalsRow: false,
    style: {
      theme: "TableStyleMedium2",
      showRowStripes: false,
      width: 200,
    },
    columns: updatedTitle ? updatedTitle : [{ name: "" }],
    rows: data?.map((e) => {
      let arr = [];
      for (let i in e) {
        if (headerKeyMapping[i]) {
          arr.push(e[i]);
        }
      }
      return arr;
    }),
  });

  // Identify indices of amount columns for alignment
  const amountColumnsIndices = [4, 5, 6, 7]; // Adjust based on your actual column structure

  // Style the header row and data rows
  const table = sheet.getTable(headerName);
  for (let i = 0; i < table.table.columns.length; i++) {
    sheet.getCell(`${String.fromCharCode(65 + i)}8`).font = { size: 12 };
    sheet.getCell(`${String.fromCharCode(65 + i)}8`).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "c5d9f1" },
    };

    for (let j = 0; j < table.table.rows.length; j++) {
      let rowCell = sheet.getCell(`${String.fromCharCode(65 + i)}${j + 9}`);
      rowCell.alignment = { 
        wrapText: true, 
        horizontal: amountColumnsIndices.includes(i) ? 'right' : 'left'  // Right align for amount columns
      };
      rowCell.border = {
        bottom: {
          style: "thin",
          color: { argb: "a6a6a6" },
        },
      };
    }
  }
  table.commit();

  // Adjust column width based on content
  sheet.columns = updatedTitle.map((title, index) => {
    const col = sheet.getColumn(index + 1);
    col.width = Math.max(
      title.name.length,
      ...data.map(row => row[Object.keys(headerKeyMapping)[index]]?.toString().length || 10)
    ) + 2; // Adding some extra space for better readability
    return col;
  });

  // Function to write the file
  const writeFile = (fileName, content) => {
    const link = document.createElement("a");
    const blob = new Blob([content], {
      type: "application/vnd.ms-excel;charset=utf-8;",
    });
    link.download = fileName;
    link.href = URL.createObjectURL(blob);
    link.click();
  };

  workbook.xlsx.writeBuffer().then((buffer) => {
    writeFile(sheetName, buffer);
  });
};
