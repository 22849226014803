import React, { useCallback, useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  Grid,
  Paper,
  Button as MuiButton,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Menu from "../../../core/Menu";
import PageHeader from "../../../core/PageHeader";
import { Form } from "../../../core/useForm";
import Input from "../../../core/controls/Input";
import Datepicker from "../../../core/controls/Datepicker";
import Button from "../../../core/controls/Button";
import Notification from "../../../core/Notification";
import { API_URL } from "../../../redux/constants/apiConstants";
import Popup from "../../../core/Popup";
import { getProductsList, getCustomersList } from "../../../redux/actions/CurrentStockReportActions";
import axios from "axios";
import { exportToExcelExcelJs } from "../../../utils/json-to-excel-credit-info";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(1),
    padding: useTheme().spacing(2),
    [useTheme().breakpoints.up("md")]: {
      margin: useTheme().spacing(5),
      padding: useTheme().spacing(3),
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));

var date = new Date(),
  mnth = ("0" + (date.getMonth() + 1)).slice(-2),
  day = ("0" + date.getDate()).slice(-2);


const initialValues = {
  from: [date.getFullYear(), mnth, day].join("-"),
  to: [date.getFullYear(), mnth, day].join("-"),
  customer: {
    id: "",
    label: "",
  },
};

const CreditInfo = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const csvDownloadRef = useRef(0);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const customerNameListState = useSelector((state) => state.customerNameListState);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const heading_with_title = {
    serial_no: "Serial No",
    //customer_id: "Customer ID",
    date: "Transcation Date",
    job_type: "Transaction Type",
    transaction_number: "Transaction Number",
    payable_amount: "Sales Value",
    paid_amount: "Payment Received",
    returned_amount: "Adjustment",
    linq_calculated_due_amount: "Current Balance",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, action) => {
      const axios_config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "Content-Type": "application/json",
        },
      };

      const api_endpoint = `${API_URL}/Report/GetCustomerCreditHistory?startDate=${values.from}&endDate=${values.to}&customerId=${values.customer.id !== "" ? values.customer.id : "0"}`;

      const response = await axios.get(api_endpoint, axios_config);
      const { data } = response;

      if (data?.statusCode === 200 && data?.dataObj?.transctionInfo?.length > 0) {

        const formatAmount = (amount) => {
          if (amount != null) {
            return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(amount));
          }
          return ''; // Return blank if amount is null or undefined
        };
        
        
        const exportData = data.dataObj.transctionInfo.map((item, index) => {
          //const formattedDate = index === 0 ? item.date : item.date.split('T')[0];
          const formattedDate = index === 0 
          ? item.date 
          : (() => {
              const date = new Date(item.date);
              const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with zero
              const month = date.toLocaleString('en-GB', { month: 'short' }); // Get short month name (e.g., "Aug")
              const year = date.getFullYear(); // Get the full year
              return `${day}-${month}-${year}`; // Format as 'DD-MMM-YYYY'
            })();
            
          return {
            serial_no: item.serial_no,
            customer_id: item.customer_id,
            date: formattedDate,//item.date, // Format date to '21-Oct-2023'
            job_type: item.job_type,
            transaction_number: item.transaction_number,
            payable_amount: formatAmount(item.payable_amount), // Format to 2 decimal places or blank
            paid_amount: formatAmount(item.paid_amount), // Format to 2 decimal places or blank
            returned_amount: formatAmount(item.returned_amount), // Format to 2 decimal places or blank
            linq_calculated_due_amount: formatAmount(item.linq_calculated_due_amount), // Format to 2 decimal places or blank  
          };
        });
        

         // Extracting customer details from the response
      const customerInfo = data.dataObj.customerInfo; // Get customer info
      const titleInfo = {
        customerId: customerInfo.customerId,
        customerName: customerInfo.customerName,
        customerAddress: customerInfo.customerAddress, // Use the customer address from API response
        reportRange: `${values.from} to ${values.to}`
      };
      const customerName = values.customer.label || "UnknownCustomer";
          exportToExcelExcelJs(
            exportData, 
            heading_with_title, 
            //"CustomerCreditHistory", 
            `CustomerCreditHistory_${customerName}`,
            `Report Range :${values.from} to ${values.to}`, 
            titleInfo);
        } else {
        action.setSubmitting(false);
        setOpenPopup({
          ...openPopup,
          title: "ZERO DATA!!!",
          subTitle: "No Data found with this specifications!",
          isOpen: true,
        });
      }
    },
  });

  useEffect(() => {
    if (userInfo) {
      dispatch(getCustomersList());
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: "/report/mapped" } },
      };
      history.push(location);
    }
  }, [dispatch, history, userInfo]);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 100,
  });

  const customerChange = useCallback((e, v) => {
    formik.setFieldValue("customer", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  return (
    <div>
      <Menu />
      <PageHeader
        icon={<AssessmentIcon />}
        title="Credit Information"
        subtitle="Generate Credit Report"
      />
      <Paper className={classes.pageContent}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Datepicker
                label="From"
                name="from"
                value={formik.values.from}
                onChange={formik.handleChange}
              />
              <Datepicker
                label="To"
                name="to"
                value={formik.values.to}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                size="small"
                id="customer"
                onChange={customerChange}
                value={formik.values.customer}
                options={customerNameListState.customerNameList || []}
                filterOptions={filterOptions}
                renderInput={(params) => (
                  <Input
                    label="Customer Name"
                    name="customer"
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <MuiButton
                className={classes.submitButtonGroup}
                size="large"
                type="submit"
              >
                <img
                  width={40}
                  height={40}
                  src={process.env.PUBLIC_URL + "/images/excel-icon.png"}
                  alt="Export"
                />
              </MuiButton>
              <Button
                color="error"
                text="Back"
                onClick={() => history.push("/")}
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
    </div>
  );
};
export default withRouter(CreditInfo);
