import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { listProformaInvoices } from "../../redux/actions/LcActions";
import { Table, Button, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const PiLists = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const proformaInvoiceList = useSelector((state) => state.proformaInvoiceList);
  const { loading, error, proformaInvoices } = proformaInvoiceList;

  useEffect(() => {
    dispatch(listProformaInvoices());
  }, [dispatch]);

  const handleCreate = () => {
    history.push("/create-proforma-invoice"); // Adjust the route as necessary
  };

  const handleEdit = (id) => {
    history.push(`/edit-proforma-invoice/${id}`); // Adjust the route as necessary
  };

  const handleEscalate = (id) => {
    // Handle escalation logic here
    alert(`Escalate Proforma Invoice ID: ${id}`);
  };

  return (
    <Container>
      <h1>Proforma Invoices</h1>
      <Button variant="primary" onClick={handleCreate} className="mb-3">
        Create
      </Button>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>LC Number</th>
              <th>LC Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {proformaInvoices.map((invoice) => (
              <tr key={invoice.LcDocId}>
                <td>{invoice.LcDocId}</td>
                <td>{invoice.LcNumber}</td>
                <td>{new Date(invoice.LcDate).toLocaleDateString()}</td>
                <td>
                  <Button
                    variant="warning"
                    onClick={() => handleEdit(invoice.LcDocId)}
                    className="mr-2"
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleEscalate(invoice.LcDocId)}
                  >
                    Escalate
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default PiLists;
